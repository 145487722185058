import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { fadeIn, fadeInOut, slideInOut, routerTransition } from '../shared/constants/animations';
import { SnackbarService } from '../core/services/snackbar/snackbar.service';
import { ToastClassEnum } from '../core/services/snackbar/snackbar.models';
import { SidenavConfig } from '../shared/models/generic.models';
import { PaperAppModalComponent } from './features/accounts/modals/paper-app-modal/paper-app-modal.component';
import { AccountUpdateMainModalComponent } from './features/account-request-workflows/modals/account-update-main-modal/account-update-main-modal.component';
import { UserModel, UsersClient } from '../core/clients/generated/client';
import { PlanningDeskRequestComponent } from './features/account-request-workflows/planning-desk-request/planning-desk-request.component';
import { Permission } from 'src/app/core/clients/generated/client';
import { CookieService } from 'ngx-cookie-service';
//import { portalV2OptInCookieName } from '../shared/constants/opt-in-cookie.constant';
import { AuthenticationService } from '../core/auth/authentication.service';
import { OpenSsoAgentSelectModalComponent } from '../shared/modals/sso-agent-select-modal/sso-agent-select-modal.component';

@Component({
  animations: [ fadeIn, fadeInOut, slideInOut, routerTransition ],
  selector: 'app-portal',
  templateUrl: './portal.component.html',
})
export class PortalComponent implements OnInit, OnDestroy {
  private breakpointSubscription: Subscription;
  private sessionPollingSubcription?: Subscription;
  perm = Permission;
  acmPortalSidenavConfig: SidenavConfig;
  loading = false;
  mediaMobile = false;
  title = ' | ';
  user?: UserModel;
  userSidenavConfig: SidenavConfig = {
    IsOpened: false,
    Mode: 'side',
  };
  //showOptOutBanner = this.cookieService.check(portalV2OptInCookieName);
  showNotificationBanner = true;
  @ViewChild('userSidenav') userSidenav!: MatSidenav;
  @ViewChild('acmPortalSidenav') acmPortalSidenav!: MatSidenav;
  constructor (
    private breakpointObserver: BreakpointObserver,
    private usersClient: UsersClient,
    private dialog: MatDialog,
    private router: Router,
    private snackbarService: SnackbarService,
    private titleService: Title,
    private cookieService:CookieService,
    private authenticationService: AuthenticationService
  ) {
    //define main sidenav config here to ensure it loads first to avoid stuttering issue
    this.acmPortalSidenavConfig = {
      IsOpened: true,
      Mode: 'over',
    };

    this.router.events
      .subscribe(() => {
        const sideNavContainer = document.querySelector('.mat-sidenav-content');
        if (sideNavContainer) sideNavContainer.scrollTop = 0;
      });

    this.breakpointSubscription = this.configureLayOut();
  }

  ngOnInit(): void {
    this.loading = true;
    this.titleService.setTitle(this.title + 'Alphastar Portal');
    this.usersClient.getUserSelf()
      .subscribe({
        next: result => {
          this.user = result;
        }, error: () => this.snackbarService.openSnackbar('There was an error getting user info.')
      })
      .add(() => this.loading = false);

    this.sessionPollingSubcription = this.authenticationService.pollSessionExpirationSubcription();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
    this.sessionPollingSubcription?.unsubscribe();
  }

  configureLayOut(): Subscription {
    return this.breakpointObserver.observe([
      Breakpoints.TabletLandscape,
      Breakpoints.WebLandscape
    ]).subscribe({
      next: result => {
        if (result.matches) {
          this.mediaMobile = false;
          this.acmPortalSidenavConfig.IsOpened = true;
          this.acmPortalSidenavConfig.Mode = 'side';
          this.userSidenavConfig.Mode = 'over';
        } else {
          this.mediaMobile = true;
          this.acmPortalSidenavConfig.IsOpened = false;
          this.acmPortalSidenavConfig.Mode = 'push';
          this.userSidenavConfig.Mode = 'push';
        }
      },
      error: () => {
        this.snackbarService.openSnackbar('Error configuring root layout', ToastClassEnum.warning);
      }
    });
  }

  openAccountUpdateModal(): void {
    this.dialog.open(AccountUpdateMainModalComponent, {
      autoFocus: false,
    });
  }

  openPlanningDeskRequestModal(): void {
    this.dialog.open(PlanningDeskRequestComponent);
  }

  openPaperAccountModal(): void {
    this.dialog.open(PaperAppModalComponent, {
      autoFocus: false,
    });
  }

  toggleUserSidenav(): void {
    this.userSidenav.toggle();
    if (this.mediaMobile) {
      if (this.acmPortalSidenav.opened) this.acmPortalSidenav.close();
    }
  }

  openOrionAgentSelectModal(destination:string|null): void {
    this.dialog.open(OpenSsoAgentSelectModalComponent, {
      minHeight: '28rem',
      autoFocus: false,
      data: {BaseUrl: 'api/sso/orion/portal/' + destination + '?appearAsCrmAgentId='}
    });
  }
}
