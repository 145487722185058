export class RequestSurveyFormModel {
  RequestID: number;
  InvestmentAccountID: number;
  RequestDescription: string;
  Rating: number;
  GoodNotes: string;
  BadNotes: string;

  constructor(RequestID: number, InvestmentAccountID: number, RequestDescription: string, Rating: number, GoodNotes: string, BadNotes: string, ) {
    this.RequestID = RequestID;
    this.InvestmentAccountID = InvestmentAccountID;
    this.RequestDescription = RequestDescription;
    this.Rating = Rating;
    this.GoodNotes = GoodNotes;
    this.BadNotes = BadNotes;
  }
}