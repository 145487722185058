import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountModel } from 'src/app/core/clients/generated/client';
import { ReallocationFormService } from 'src/app/portal/features/account-request-workflows/reallocation-request/services/reallocation-form.service';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { fadeIn } from 'src/app/shared/constants/animations';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalData } from 'src/app/shared/modals/confirmation-modal/modal-data.models';
import { RequestExitConfirmationData } from '../shared/constants/workflow-navigation.constants';
import { AccountUpdateStepEnum, RequestTypeEnum } from '../shared/enums/account-update.enum';
import { AccountWorkflowNavModel, SolicitationOptionModel } from '../shared/models/workflow.models';
import { AllocationSleeveFormArray } from '../shared/models/allocation-form.models';
import { AccountRequestModalHeaderComponent } from '../shared/components/account-request-modal-header/account-request-modal-header.component';

@Component({
  selector: 'app-reallocation-request',
  templateUrl: './reallocation-request.component.html',
  providers: [ReallocationFormService],
  animations: [fadeIn]
})
export class ReallocationRequestComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ReallocationRequestComponent>,
    private snackbarService: SnackbarService,
    public reAllocationFormService: ReallocationFormService
  ) { }
  @Output() canceled = new EventEmitter();
  @Input() account?: AccountModel;
  @Input() workflow?: AccountWorkflowNavModel;
  @Input() solicitation?: SolicitationOptionModel;
  @ViewChild('header') header?:AccountRequestModalHeaderComponent;
  isLoading = false;
  isSubmitting = false;
  isSubmitted = false;
  isComplete = false;
  submitCount = 0;
  crmRequestID?: number;
  accountUpdateStepEnum = AccountUpdateStepEnum;
  requestTypeEnum = RequestTypeEnum;
  form = this.reAllocationFormService.form;
  pages = this.reAllocationFormService.pages;

  ngOnInit(): void {
    if (this.account) {
      this.reAllocationFormService.account = this.account;
      this.reAllocationFormService.initialAmount = this.account.accountValue ?? 0;
      this.reAllocationFormService.addSleeves(this.account);
      this.reAllocationFormService.initSleeveInput();
    }

    if (this.solicitation) {
      this.reAllocationFormService.isSolicited = this.solicitation?.isSolicited;
    }
  }

  get sleevesFormArray(): AllocationSleeveFormArray {
    return this.form.controls.allocationConfiguration.controls.sleeves as AllocationSleeveFormArray;
  }

  get showCustomHoldings(): boolean {
    return this.form.controls.allocationConfiguration.value.showCustomHoldings;
  }

  uploadFiles(files: File[]): void {
    this.reAllocationFormService.files = files;
  }

  onSubmit(): void {
    if (this.account) {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
      } else {
        this.isSubmitting = true;
        this.reAllocationFormService.postRequest(this.account.investmentAccountID).subscribe({
          next: (result) => {
            //used to pass to complete page for survey
            this.crmRequestID = result;
          },
          error: () => {
            this.submitCount++;
            // for work around on sometimes the first request failing submitting to the crm.
            if (this.submitCount < 2){
              console.log('received error, retrying in 2 seconds');
              setTimeout(() =>{
                this.onSubmit();
              }, 2000);
            } else {
              this.isSubmitting = false;
              this.isSubmitted = true;
              this.isComplete = false;
              this.submitCount = 0;
            }
          },
          complete: () => {
            this.isSubmitting = false;
            this.isSubmitted = true;
            this.isComplete = true;
          }
        });
      }
    }
  }

  closeRequest(): void {
    if (this.isSubmitted && this.isComplete) {
      this.dialogRef.close(false);
      return;
    }

    const confirmationDialog = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
      data: RequestExitConfirmationData
    });

    confirmationDialog.afterClosed().subscribe(res => {
      if (res) this.dialogRef.close(false);
      else return;
    });
  }

  cancelRequest(): void {
    const confirmationDialog = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
      data: RequestExitConfirmationData
    });

    confirmationDialog.afterClosed().subscribe(res => {
      if (res) {
        this.canceled.emit();
      } else return;
    });
  }
}
