import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { RequestTrackerPageComponent } from './pages/request-tracker-page.component';
import { RequestTrackerListComponent } from './components/request-tracker-list/request-tracker-list.component';
import { RequestTrackerDetailModalComponent } from './modals/request-tracker-detail-modal/request-tracker-detail-modal.component';
import { MessagesAndFileTimelineComponent } from './components/request-tracker-messages-and-files/message-and-file-timeline.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    RequestTrackerPageComponent,
    RequestTrackerListComponent,
    RequestTrackerDetailModalComponent,
    MessagesAndFileTimelineComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    Map
  ]
})
export class RequestTrackerModule { }
