<section>
  <label class="mb-5 text-sm font-bold text-gray-dark required-label">Timeline</label>
  <div class="overflow-auto max-h-400">
    @for (n of notesAndFiles; track n) {

    <div class="flex flex-col pt-5 pl-10 pr-10 mt-10 rounded bg-gray-lightest border border-solid border-gray-lighter">
      @if (n.Note) {
      <div class="flex flex-col p-5 mt-5 rounded bg-white">
        <div class="flex flex-row text-sm whitespace-pre-wrap overflow-wrap-text">
          <span class="whitespace-pre-wrap overflow-wrap-text text-sm"
            [ngClass]="{'text-gray-darker' : n.CreatedByUserName !== null && n.CreatedByUserName.includes('acm'), 'text-blue-darker' : n.CreatedByUserName !== null && !n.CreatedByUserName.includes('acm')}">
            {{ n.Note }}</span>
        </div>
      </div>
      }
      <div class="flex flex-row p-5 justify-between">
        <div class="flex flex-row">
          @if (n.ExternalStatus) {
          <div class="flex flex-row text-sm">
            <span class="text-orange mr-10">
              <mat-icon class="mi-18">report_problem</mat-icon>
            </span>
            <span class="text-gray-dark mr-5">Status updated to: </span>
            <span [ngStyle]="{'color': 
                      n.StatusID === 4 ? '#D71D00' : 
                      n.StatusID === 5 ? '#127161' : 
                      n.StatusID === 6 ? '#FFAF3D' : 
                      n.StatusID === 7 ? '#D71D00' : 
                      n.StatusID === 8 ? '#D71D00' : 
                      n.StatusID === 9 ? '#D71D00' : 
                      '#198447'}"> {{ n.ExternalStatus }}</span>
          </div>
          }
          @if (n.FileID !== null && n.FileID !== undefined) {
          <div class="flex flex-row text-sm">
            <span class="text-orange mr-5">
              <mat-icon class="mi-18 text-blue-darker">description</mat-icon>
            </span>
            <span class="text-gray-dark mr-10">File Uploaded: </span>
            <a class="flex underline rounded cursor-pointer text-blue-darker hover:no-underline focus:no-underline"
              [href]="filePrefix + n.FileID.toString()" target="_blank" [attr.aria-label]="'Open ' + n.FileName">
              <span class="text-sm leading-normal">{{n.FileName}}</span>
            </a>
          </div>
          }
        </div>
        <div class="flex flex-row text-xs">
          <span class="whitespace-pre-wrap overflow-wrap-text"
            [ngClass]="{'text-gray-darker' : n.CreatedByUserName !== null ? n.CreatedByUserName.includes('acm') : '', 'text-blue-darker' : n.CreatedByUserName !== null && !n.CreatedByUserName.includes('acm')}">
            {{ n.CreatedByUser }}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
          <span class="whitespace-pre-wrap overflow-wrap-text text-xs">{{ n.CreatedOn | date: 'MM/dd/yyyy' }} {{
            n.CreatedOn | date: 'shortTime'
            }}
          </span>
        </div>
      </div>
    </div>
    }
  </div>
</section>