import { AcmMediaAsset } from 'src/app/core/clients/generated/client';

export interface DevelopmentCenterWistiaSection {
  Name: string;
  SectionId: string;
  VideoAssets: DevelopmentCenterWistiaAsset[];
  FileAssets: DevelopmentCenterWistiaAsset[];
  AllAssets: DevelopmentCenterWistiaAsset[];
  ShowVideosAsLinks?: boolean;
  ShowName?: boolean;
}

export interface DevelopmentCenterWistiaAsset {
  Description?: string;
  Asset: AcmMediaAsset;
}

export interface DevelopmentCenterWistiaSectionMap {
  Name: string;
  SectionId: string;
}

export interface DevelopmentCenterWistiaAssetMap {
  AssetId: string;
  Description: string;
}

export enum DevelopmentCenterWistiaSectionEnum {
  NextLevelTop = '1',
  NextLevelTopical = '2',
  NextLevelPreviousEvents = '3',
  NextLevelDigitalResources = '4',
  AlphaStartTop = '5',
  AlphaStartViewMore = '6',
  VirtualLearningTop = '7',
  VirtualLearningLearnMore = '8',
  FinishStrongTop = '9',
  FinishStrongLearnMore = '10',
  BestPracticesTop = '11',
  BestPracticesLearnMore = '12',
  RecentCommunications = '13',
  CoffeeWithComplianceTop = '14',
  CoffeeWithComplianceLearnMore = '15',
  SalesSummit2024Top = '16',
  SalesSummit2024LearnMore = '17',
  AlphastarContentLibrary = '18',
  PracticeManagement = '19',
  ClientEngagement = '20',
  MarketingCollateral = '21',
}