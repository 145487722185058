@if (account && workflow) {
  <form @fadeIn [formGroup]="advisorMessageFormGroup" (ngSubmit)="onSubmit()" class="flex flex-col w-full h-full overflow-hidden">
    <app-account-request-modal-header #header [title]="workflow.title" [account]="account" (closeRequested)="closeRequest()"></app-account-request-modal-header>
    @if (isSubmitting) {
    <div class="flex-grow w-full loader-wrap" @fadeIn>
      <div class="loader-sm is-blue"></div>
    </div>
    }
    @if (!isSubmitting && !isSubmitted) {
    <section class="flex flex-col flex-grow overflow-auto h-full">
      @if (currentStep === accountUpdateStepEnum.step1) {
      <div class="flex flex-col w-full">
        <div class="flex flex-col overflow-auto p-30 gap-y-30">
          <div class="flex flex-col gap-y-30" [formGroup]="advisorMessageFormGroup" (ngSubmit)="onSubmit()">
            <div class="w-full">
              <section>
                <div class="flex flex-col flex-none">
                  <label class="mb-5 text-sm font-bold text-gray-dark required-label" for="message">New Message</label>
                  <textarea class="textarea" formControlName="message" cols="30" rows="3"
                    placeholder="Enter your message here..."></textarea>
                </div>
                <div class="flex flex-col flex-none mt-10">
                  <label class="mb-5 text-sm font-bold text-gray-dark required-label" for="message">New Upload</label>
                  <app-file-dropper (fileChangeEvent)="uploadFiles($event)"></app-file-dropper>

                </div>
                <div class="flex flex-row align-middle justify-end">
                  @if ((advisorMessageFormGroup.controls.message.touched &&
                  advisorMessageFormGroup.controls.message.errors) || (advisorMessageFormGroup.controls.files.touched &&
                  advisorMessageFormGroup.controls.files.errors)) {
                  <span class="w-full mt-5 text-sm text-red">
                    @if (advisorMessageFormGroup.controls.files.hasError('required') ||
                    advisorMessageFormGroup.controls.message.hasError('required')) {
                    <span>A message or upload is required.</span>
                    }
                  </span>
                  }
                  <!-- <button mat-button type="submit" class="mt-15 ml-auto button is-md is-primary"
                    [disabled]="isLoading || (this.fileMessageData && !this.selectedAccount)">
                    {{isLoading ? "Submitting" : "Submit"}}</button> -->
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      }
      @if (currentStep === accountUpdateStepEnum.step2) {
      <div class="flex flex-col w-full">
        <div class="flex flex-col overflow-auto p-30 gap-y-30">
          <app-general-account-review-step [advisorMessageForm]="advisorMessageFormGroup"></app-general-account-review-step>
        </div>
      </div>
      }
    </section>
    }
    @if (!isSubmitting && isSubmitted) {
    <section class="flex flex-col flex-grow h-full overflow-auto">
      <app-complete [complete]="isComplete" [requestType]="requestTypeEnum.generalAccount" [account]="account" [crmRequestID]="crmRequestID"></app-complete>
    </section>
    }
    @if (!isComplete) {
      <footer>
        @if (!isSubmitted && currentStep === accountUpdateStepEnum.step1) {
          <button [disabled]="isSubmitting" type="button" mat-button class="flex justify-center m-10 button is-primary is-inverted is-lg is-outlined"
          (click)="cancelRequest()">Back</button>
        }
        @if (!isSubmitted && (currentStep > accountUpdateStepEnum.step1)) {
          <button [disabled]="isSubmitting" type="button" mat-button class="flex justify-center m-10 button is-primary is-inverted is-lg is-outlined"
          (click)="previousStep();">Back</button>
        }
        @if (!isSubmitted && (currentStep < pages.length)) {
          <button [disabled]="isSubmitting" type="button" mat-button class="flex justify-center m-10 ml-auto button is-primary is-lg"
            (click)="nextStep(pages[currentStep - 1].formGroup)"
          >Next</button>
        }
        @if (!isSubmitted && (currentStep === pages.length)) {
          <button [disabled]="isSubmitting" type="submit" mat-button class="flex justify-center m-10 ml-auto button is-primary is-lg">
          {{isSubmitting ? "Submitting" : "Submit"}}</button>
        }
      </footer>
      }          
  </form>  
}