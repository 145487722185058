<div class="flex flex-col w-full h-full">
  @if (modalLoading) {
    <div class="flex-grow w-full loader-wrap" @fadeIn>
      <div class="loader-sm is-blue"></div>
    </div>
  }
  @if (!modalLoading && selectedWorkflow === requestTypeEnum.accountSelection) {
    <header @fadeIn>
      <h2 class="m-10 mr-20 text-lg font-bold">New Service Request</h2>
      <button mat-button class="m-10 ml-auto icon-button is-primary is-inverted" type="button" mat-dialog-close>
        <i class="material-icons">close</i>
      </button>
    </header>
    <section class="m-10 dialog-content gap-y-30" @fadeIn>
      <div class="flex flex-col">
        <span class="mb-20 font-bold required-label">
        Which account are you requesting an update for?</span>
        <form [formGroup]="searchField" class="relative flex justify-center">
          <input name="searchTextAccount" aria-label="Search Accounts input" matInput
            placeholder="Search for an account..." formControlName="searchInput" type="text"
            [matAutocomplete]="accountauto" class="w-full input is-lg" required>
          @if (selectedAccountId || searchField.controls.searchInput.value) {
            <button mat-button
              class="absolute right-0 h-full m-2 icon-button is-primary is-inverted text-gray-dark hover:bg-transparent"
              type="button" (click)="resetSearch()">
              <span class="material-icons mi-24">close</span>
            </button>
          }
        </form>
        <mat-autocomplete #accountauto="matAutocomplete" ngDefaultControl>
          @if (searchField.controls.searchInput.value && searchField.controls.searchInput.value.length > 2 && !selectedAccountId) {
            @if (!searchLoading) {
              @for (account of accounts; track account) {
                <mat-option class="flex"
                  [value]="account.custodian + (!!account.accountNumber ? ' - ' + account.accountNumber : '') + ' - ' + account.clients"
                  (click)="selectAccount(account)" @fadeIn>
                  <div class="flex">
                    <div class="flex flex-col pr-20">
                      <span class="mb-5 font-bold text-gray-dark" [textContent]="account.investmentAccountModel"></span>
                      <span class="text-gray">
                        {{account.custodian}}
                        {{account.accountNumber && '-'}}
                        {{account.accountNumber || ''}}
                        {{account.investmentAccountType && '-'}}
                        {{account.investmentAccountType || ''}}
                      </span>
                    </div>
                    <div class="ml-auto">
                      <span class="font-bold" [textContent]="account.clients"></span>
                    </div>
                  </div>
                </mat-option>
              }@empty {
                <mat-option class="bg-gray-lightest" readonly fadeIn>
                  <span class="text-sm text-gray">
                    No results found.
                  </span>
                </mat-option>
              }
            }
            @else{
              <mat-option readonly disabled @fadeIn>
                <div class="flex-grow w-full loader-wrap">
                  <div class="loader-sm is-blue"></div>
                </div>
              </mat-option>
            }
          }
        </mat-autocomplete>
        @if (searchField.invalid && searchField.touched) {
          <div class="mt-5 text-xs text-red">
            This field is required.
          </div>
        }
        <div class="flex flex-col">
          <span class="mb-20 font-bold mt-15">Select the type of request:</span>
          <div class="flex flex-col w-full gap-y-10 mb-30">
            @for (navItem of workflowNavItems; track navItem) {
              @if (navItem.order !== requestTypeEnum.planningDesk) {
                <button mat-button
                  class="button justify-start {{!selectedAccountId ? 'disabled bg-gray-lighter' : selectedWorkflowNavItem?.title === navItem.title ? 'is-outlined bg-blue-lightest font-bold ' : ''}} {{navItem.buttonClass}} is-inverted font-semibold flex items-center"
                  [matTooltip]="'Click to go to ' + navItem.title.toLowerCase()" matTooltipPosition="right" (click)="selectedWorkflowNavItem = navItem; 
                  selectedWorkflowNavItem.order === requestTypeEnum.generalAccount ? selectedSolicitationOption = undefined : ''"
                  matTooltipShowDelay="1000">
                  <i class="mr-20 material-icons mi-22" [style.transform]="navItem.iconTransform" [textContent]="navItem.icon"></i>
                  <span [textContent]="navItem.title"></span>
                </button>
              }
              <!-- <button mat-button class="button {{!selectedAccountId ? 'disabled bg-gray-lighter' : navItem.buttonClass}} is-inverted font-semibold flex items-center {{ selectedWorkflowNavItem?.title === navItem.title ? 'is-outlined bg-blue-lightest font-bold' : ''}}"
              [matTooltip]="'Click to go to ' + navItem.title.toLowerCase()" matTooltipPosition="right"
              matTooltipShowDelay="1000" (click)="selectedWorkflow = navItem.order; selectedWorkflowNavItem = navItem"
              *ngIf="navItem.order === requestTypeEnum.planningDesk">
              <i class="mr-20 material-icons mi-20" [textContent]="navItem.icon"></i>
              <span [textContent]="navItem.title"></span>
            </button> -->
          }
        </div>
        @if (selectedWorkflowNavItem && selectedWorkflowNavItem.order !== requestTypeEnum.generalAccount) {
          <span class="mb-20 font-bold mt-5">Select who initiated this request?</span>
          <div class="flex flex-col w-full gap-y-10">
            @for (item of solicitationOptionItems; track item) {
              <button mat-button class="button justify-start {{!selectedAccountId ? 'disabled bg-gray-lighter' : item.buttonClass}} font-semibold is-inverted flex items-center {{ selectedSolicitationOption?.title === item.title ? 'is-outlined bg-blue-lightest font-bold' : ''}}"
                [matTooltip]="'Click to go to ' + item.title" matTooltipPosition="right" matTooltipShowDelay="1000" (click)="selectedSolicitationOption = item">
                <i class="mr-20 material-icons mi-20" [textContent]="item.icon"></i>
                <span [textContent]="item.title"></span>
              </button>
            }
          </div>
        }
      </div>
    </div>
  </section>
  <footer @fadeIn>
    <!--<button mat-button class="flex m-10 ml-auto border button is-primary is-inverted is-lg is-outlined"  mat-dialog-close>Cancel
  Request</button>-->
  <button [disabled]="!selectedWorkflowNavItem || selectedWorkflowNavItem.order !== requestTypeEnum.generalAccount ? !selectedSolicitationOption : false" type="button" mat-button class="m-10 ml-auto button is-primary is-lg flex justify-center"
  (click)="selectNavItem(selectedWorkflowNavItem, selectedSolicitationOption)">Next</button>
</footer>
}
@if (selectedWorkflowNavItem && selectedWorkflow !== requestTypeEnum.accountSelection) {
  @if (account) {
    @if (selectedWorkflow === requestTypeEnum.contribution) {
      <app-contribution-request [account]="account" [workflow]="selectedWorkflowNavItem" [solicitation]="selectedSolicitationOption" (canceled)="resetEvent()"
      @fadeIn></app-contribution-request>
    }
    @else if (selectedWorkflow === requestTypeEnum.distribution) {
      <app-distribution-request [account]="account" [workflow]="selectedWorkflowNavItem" [solicitation]="selectedSolicitationOption" (canceled)="resetEvent()"
      @fadeIn></app-distribution-request>
    }
    @else if (selectedWorkflow === requestTypeEnum.reallocation) {
      <app-reallocation-request [account]="account" [workflow]="selectedWorkflowNavItem" [solicitation]="selectedSolicitationOption" (canceled)="resetEvent()"
      @fadeIn></app-reallocation-request>
    }
    @else if (selectedWorkflow === requestTypeEnum.closeAccount) {
      <app-close-account-request [account]="account" [workflow]="selectedWorkflowNavItem" [solicitation]="selectedSolicitationOption" (canceled)="resetEvent()"
      @fadeIn></app-close-account-request>
    }
    @else if (selectedWorkflow === requestTypeEnum.generalAccount) {
      <app-general-account-request  [account]="account" [workflow]="selectedWorkflowNavItem" (canceled)="resetEvent()"></app-general-account-request>
    }
  }
  @if (selectedWorkflow===requestTypeEnum.planningDesk) {
    <app-planning-desk-request [workflow]="selectedWorkflowNavItem" (canceled)="resetEvent()"
      @fadeIn>
    </app-planning-desk-request>
  }
}
</div>