@if (complete === true) {

  @if (showSurvey === true) {
    @if (surveyComplete === true) {
      <div class="flex flex-col items-center justify-center self-center w-full gap-y-30 min-h-500">
        <span class="material-icons mi-96 text-green-dark">task_alt</span>
        <label class="font-semibold">Thank you for your feedback!</label>
        <button type="button" class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
        (click)="closeRequest()">Close</button>
      </div>
    
    } @else {
      <div class="flex flex-col items-center justify-center self-center w-full gap-y-30 min-h-500 mt-25">
        <span class="material-icons mi-48 text-green-dark">task_alt</span>
        <label class="font-semibold">Thank You! Your {{requestTypeName}} request has been submitted successfully.</label>
        <div class="border-b border-solid border-gray-lighter w-400"></div>
        <div>How would you rate your experience?</div>
        <div class="flex flex-row items-center justify-center cursor-pointer">
          <span (click)="survey.Rating = 1"
            [ngClass]="{'text-yellow-light' : survey.Rating >= 1, 'text-gray-light' : survey.Rating < 1}"
            class="material-icons mi-36">grade</span>
          <span (click)="survey.Rating = 2"
            [ngClass]="{'text-yellow-light' : survey.Rating >= 2, 'text-gray-light' : survey.Rating < 2}"
            class="material-icons mi-36">grade</span>
          <span (click)="survey.Rating = 3"
            [ngClass]="{'text-yellow-light' : survey.Rating >= 3, 'text-gray-light' : survey.Rating < 3}"
            class="material-icons mi-36">grade</span>
          <span (click)="survey.Rating = 4"
            [ngClass]="{'text-yellow-light' : survey.Rating >= 4, 'text-gray-light' : survey.Rating < 4}"
            class="material-icons mi-36">grade</span>
          <span (click)="survey.Rating = 5"
            [ngClass]="{'text-yellow-light' : survey.Rating >= 5, 'text-gray-light' : survey.Rating < 5}"
            class="material-icons mi-36">grade</span>
        </div>
        <textarea [disabled]="survey.Rating === 0" class="textarea" [(ngModel)]="survey.GoodNotes" cols="80" rows="5"
          placeholder="What were the most pleasant aspects of your experience?">
                </textarea>
        <textarea [disabled]="survey.Rating === 0" class="textarea" [(ngModel)]="survey.BadNotes" cols="80" rows="5"
          placeholder="What were the most frustrating aspects that caused the most friction?">
            </textarea>
        <div class="flex flex-row">
          <button type="button" class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
            (click)="submitSurvey()" [disabled]="survey.Rating === 0">Submit</button>
    
          <button type="button" *ngIf="survey.Rating > 0" class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
            (click)="closeRequest()">Skip</button>
        </div>
      </div>
    }
  } @else {
    <div class="flex flex-col items-center justify-center self-center w-full gap-y-30 min-h-500">
      <span class="material-icons mi-96 text-green-dark">task_alt</span>
      <label class="font-semibold">Thank You! Your {{requestTypeName}} request has been submitted successfully.</label>
      <button type="button" class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
      (click)="closeRequest()">Close</button>
    </div>
  }
  
} @else {
<div class="flex flex-col items-center justify-center self-center w-full gap-y-30 min-h-300">
  <span class="material-icons mi-96 text-red-dark">error</span>
  <label class="font-bold">Uh Oh!</label>
  <label class="font-semibold">Something went wrong with submitting your {{requestTypeName}} request.</label>
  <label class="font-semibold">Please Try submitting again.</label>
</div>
}