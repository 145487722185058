import { AcmMediaFileType, AcmMediaProject, AcmMediaSection } from 'src/app/core/clients/generated/client';
import { DevelopmentCenterWistiaSectionList } from 'src/app/portal/features/development-center/constants/development-center.constants';
import { DevelopmentCenterWistiaSection, DevelopmentCenterWistiaSectionEnum, DevelopmentCenterWistiaSectionMap } from 'src/app/portal/features/development-center/models/development-center.models';

export const getWistiaSection = (wistiaProject: AcmMediaProject, sectionId: DevelopmentCenterWistiaSectionEnum, showName?: boolean): DevelopmentCenterWistiaSection | undefined => {
  const mappedSection: DevelopmentCenterWistiaSectionMap | undefined = DevelopmentCenterWistiaSectionList.find(m => m.SectionId === sectionId);
  const filteredSection: AcmMediaSection | undefined = wistiaProject.content.find(s => s.sectionId === sectionId);
  if (mappedSection && filteredSection) {
    const section: DevelopmentCenterWistiaSection = {
      Name: mappedSection.Name,
      ShowName: showName || false,
      SectionId: sectionId,
      VideoAssets: filteredSection.assets.filter(a => a.mediaType === AcmMediaFileType.Video).map(a => {
        return {
          Asset: a,
          Description: a.name,
        };
      }),
      FileAssets: filteredSection.assets.filter(a => a.mediaType === AcmMediaFileType.File).map(a => {
        return { Asset: a };
      }),
      AllAssets: filteredSection.assets.map(a => {
        return { Asset: a };
      })
    };
    return section;
  }
  return;
};