@if (account && workflow) {
  <form @fadeIn [formGroup]="form" (ngSubmit)="onSubmit()" class="flex flex-col w-full h-full overflow-hidden">
    <app-account-request-modal-header #header [title]="workflow.title" [account]="account" [formService]="distributionFormService" (closeRequested)="closeRequest()"></app-account-request-modal-header>
    @if (isSubmitting) {
      <div class="flex-grow w-full loader-wrap" @fadeIn>
        <div class="loader-sm is-blue"></div>
      </div>
    }
    @if (!isSubmitting && !isSubmitted) {
      <section class="flex flex-col flex-grow h-full overflow-auto">
        @if (distributionFormService.currentStep === accountUpdateStepEnum.step1) {
          <app-distribute-funds
            [parentForm]="form.controls.amount"
          [account]="account"></app-distribute-funds>
        }
        @else if (distributionFormService.currentStep === accountUpdateStepEnum.step2) {
          <app-allocation-step class="h-full" [investmentRequestFormService]="distributionFormService" [parentForm]="form.controls.allocationConfiguration" [initialAmount]="distributionFormService.initialAmount" [requestType]="requestTypeEnum.distribution"></app-allocation-step>
        }
        @else if (distributionFormService.currentStep === accountUpdateStepEnum.step3) {
          <app-delivery-methods [parentForm]="form.controls.deliveryOptions" [requestType]="requestTypeEnum.distribution"></app-delivery-methods>
        }
        @else if (distributionFormService.currentStep === accountUpdateStepEnum.step4) {
          <app-review-step [investmentRequestFormService]="distributionFormService" [requestType]="requestTypeEnum.distribution"></app-review-step>
        }
      </section>
    }
    @if (!isSubmitting && isSubmitted) {
      <section class="flex flex-col flex-grow h-full overflow-auto">
        <app-complete [complete]="isComplete" [requestType]="requestTypeEnum.distribution" [account]="account" [crmRequestID]="crmRequestID" ></app-complete>
      </section>
    }
    @if (!showCustomHoldings && !isComplete) {
      <footer >
        @if (distributionFormService.currentStep === accountUpdateStepEnum.step1) {
          <button [disabled]="isSubmitting" type="button" class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
          (click)="cancelRequest()">Back</button>
        }
        @if ((distributionFormService.currentStep > accountUpdateStepEnum.step1)) {
          <button [disabled]="isSubmitting" type="button" mat-button class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
          (click)="distributionFormService.goToPreviousStep(); isSubmitted=false;">Back</button>
        }
        @if ((distributionFormService.currentStep <= (pages.length - 1))) {
          <button [disabled]="isSubmitting" type="button" mat-button class="m-10 ml-auto button is-primary is-lg flex justify-center"
          (click)="distributionFormService.goToNextStep(pages[distributionFormService.currentStep - 1].formGroup, pages.length)">Next</button>
        }
        @if (distributionFormService.currentStep === accountUpdateStepEnum.step4) {
          <button [disabled]="isSubmitting" type="submit" class="m-10 ml-auto button is-primary is-lg flex justify-center">{{isSubmitting ? "Submitting" : "Submit"}}</button>
        }
      </footer>
    }
  </form>
}