<section @fadeIn class="flex flex-col w-full h-full pt-15">
  <!-- For now, don't show ability to reply w/ message or file for Planning Desk requests 3/12/25 -->
  @if (this.fileMessageData && this.fileMessageData.onBehalfOfRequest?.requestType !== requestTypeEnum.PlanningDesk &&
  statusId !== 5 && statusId !== 6)
  {
  <form [formGroup]="advisorMessageFormGroup" (ngSubmit)="onSubmit()">
    <div class="w-full">
      <section>
        <div class="flex flex-col flex-none">
          <label class="mb-5 text-sm font-bold text-gray-dark required-label" for="message">New Message</label>
          <textarea class="textarea" formControlName="message" cols="30" rows="3"
            placeholder="Enter your message here..."></textarea>
        </div>
        <div class="flex flex-col flex-none mt-10">
          <label class="mb-5 text-sm font-bold text-gray-dark required-label" for="message">New Upload</label>
          <app-file-dropper (fileChangeEvent)="uploadFiles($event)"></app-file-dropper>

        </div>
        <div class="flex flex-row align-middle justify-end">
          @if ((advisorMessageFormGroup.controls.message.touched &&
          advisorMessageFormGroup.controls.message.errors) || (advisorMessageFormGroup.controls.files.touched &&
          advisorMessageFormGroup.controls.files.errors)) {
          <span class="w-full mt-5 text-sm text-red">
            @if (advisorMessageFormGroup.controls.files.hasError('required') ||
            advisorMessageFormGroup.controls.message.hasError('required')) {
            <span>A message or upload is required.</span>
            }
          </span>
          }
          <button mat-button type="submit" class="mt-15 ml-auto button is-md is-primary"
            [disabled]="isLoading || (this.fileMessageData && !this.selectedAccount)">
            {{isLoading ? "Submitting" : "Submit"}}</button>
        </div>
      </section>
    </div>
  </form>
  }
</section>