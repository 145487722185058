import { ManagerFeeEnum, InvestmentStrategyModel, AdditionalOfferingModel, ModelMinimumEnum, MoneyManagerEnum, RiskToleranceEnum, TagEnum, LogoEnum } from '../models/investments.models';

export const InvestmentStrategies: InvestmentStrategyModel[] = [
  {
    Id: '1',
    Name: 'Alphastar Core Global Equity Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '2',
    Name: 'Alphastar Core International Equity Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '3',
    Name: 'Alphastar Core US Equity Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '6',
    Name: 'Alphastar Core Patriot Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '7',
    Name: 'Alphastar Primary Equity Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.FiveHundred,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '8',
    Name: 'Alphastar TWIN Enhanced 50 Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.TwentyTwo,
    ModelMinimum: ModelMinimumEnum.FiftyThousand,
    Tags: [TagEnum.Growth, TagEnum.TaxEfficient],
  },
  {
    Id: '50',
    Name: 'TWIN Enhanced-Tax SMA',
    LogoUrl: LogoEnum.Twin,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Twin,
    ManagerFee: ManagerFeeEnum.Forty,
    ModelMinimum: ModelMinimumEnum.TwoHundredFiftyThousand,
    Tags: [TagEnum.Growth, TagEnum.TaxEfficient],
    Description: 'This strategy cannot be commingled in the same account as other strategies – a separate account is required.',
  },
  {
    Id: '9',
    Name: 'Inspire Aggressive Strategy',
    LogoUrl: LogoEnum.Inspire,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Inspire,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FiveThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '10',
    Name: 'Inspire Equity Strategy',
    LogoUrl: LogoEnum.Inspire,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Inspire,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FiveThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '11',
    Name: 'Russell Inv Active-Passive Equity Growth Model Strategy',
    LogoUrl: LogoEnum.Russell,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Russell,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.Growth],
    InvestmentLinks: [{
      Title: 'Russell Active-Passive Resources',
      Link: 'https://russellinvestments.com/us/fund-center/model-portfolios/hybrid-model-strategies',
    }],
  },
  {
    Id: '12',
    Name: 'Russell Inv Tax-Managed Equity Growth Model Strategy (Class s)',
    LogoUrl: LogoEnum.Russell,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Russell,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.Growth, TagEnum.TaxEfficient],
    InvestmentLinks: [{
      Title: 'Russell Tax-Managed Resources',
      Link: 'https://russellinvestments.com/us/fund-center/model-portfolios/tax-managed-model-strategies',
    }],
  },
  {
    Id: '13',
    Name: 'Vanguard CRSP 100% Equity Portfolio',
    LogoUrl: LogoEnum.Vanguard,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Vanguard,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.Growth],
    InvestmentLinks: [{
      Title: 'Vanguard CRSP Portfolio Resources',
      Link: 'https://advisors.vanguard.com/portfolio-construction-tools/model-portfolios',
    }],
  },
  {
    Id: '14',
    Name: 'Alphastar Core Dynamic Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.ModeratelyAggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '15',
    Name: 'Alphastar Core Opportunity Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.ModeratelyAggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '17',
    Name: 'Alphastar Primary Dynamic Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Moderate,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.FiveHundred,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '18',
    Name: 'Russell Inv Active-Passive Growth Model Strategy',
    LogoUrl: LogoEnum.Russell,
    RiskTolerance: RiskToleranceEnum.ModeratelyAggressive,
    MoneyManager: MoneyManagerEnum.Russell,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.Growth],
    InvestmentLinks: [{
      Title: 'Russell Active-Passive Resources',
      Link: 'https://russellinvestments.com/us/fund-center/model-portfolios/hybrid-model-strategies',
    }],
  },
  {
    Id: '19',
    Name: 'Russell Inv Tax-Managed Growth Model Strategy (Class s)',
    LogoUrl: LogoEnum.Russell,
    RiskTolerance: RiskToleranceEnum.ModeratelyAggressive,
    MoneyManager: MoneyManagerEnum.Russell,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.Growth, TagEnum.TaxEfficient],
    InvestmentLinks: [{
      Title: 'Russell Tax-Managed Resources',
      Link: 'https://russellinvestments.com/us/fund-center/model-portfolios/tax-managed-model-strategies',
    }],
  },
  {
    Id: '20',
    Name: 'Vanguard CRSP 80% Equity/ 20% Fixed Income Portfolio',
    LogoUrl: LogoEnum.Vanguard,
    RiskTolerance: RiskToleranceEnum.ModeratelyAggressive,
    MoneyManager: MoneyManagerEnum.Vanguard,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.Growth],
    InvestmentLinks: [{
      Title: 'Vanguard CRSP Portfolio Resources',
      Link: 'https://advisors.vanguard.com/portfolio-construction-tools/model-portfolios',
    }],
  },
  {
    Id: '21',
    Name: 'Alphastar Core Balanced Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.ModeratelyConservative,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth, TagEnum.Income],
  },
  {
    Id: '22',
    Name: 'Alphastar Core Enhanced Income Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Moderate,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Income],
  },
  {
    Id: '23',
    Name: 'Alphastar Core Fundamental Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Moderate,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth, TagEnum.Income],
  },
  {
    Id: '24',
    Name: 'Alphastar Primary Balanced Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.ModeratelyConservative,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.FiveHundred,
    Tags: [TagEnum.Growth, TagEnum.Income],
  },
  {
    Id: '25',
    Name: 'Betashield Global Equity Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Thirty,
    ModelMinimum: ModelMinimumEnum.TwentyFiveThousand,
    Tags: [TagEnum.Growth, TagEnum.RiskMitigation],
  },
  {
    Id: '26',
    Name: 'Betashield International Equity Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Thirty,
    ModelMinimum: ModelMinimumEnum.TwentyFiveThousand,
    Tags: [TagEnum.Growth, TagEnum.RiskMitigation],
  },
  {
    Id: '27',
    Name: 'Betashield Opportunity Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.ModeratelyAggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Thirty,
    ModelMinimum: ModelMinimumEnum.TwentyFiveThousand,
    Tags: [TagEnum.Growth, TagEnum.RiskMitigation],
  },
  {
    Id: '28',
    Name: 'Betashield US Equity Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Thirty,
    ModelMinimum: ModelMinimumEnum.TwentyFiveThousand,
    Tags: [TagEnum.Growth, TagEnum.RiskMitigation],
  },
  {
    Id: '29',
    Name: 'Inspire Moderate Strategy',
    LogoUrl: LogoEnum.Inspire,
    RiskTolerance: RiskToleranceEnum.Moderate,
    MoneyManager: MoneyManagerEnum.Inspire,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FiveThousand,
    Tags: [TagEnum.Growth, TagEnum.Income]
  },
  {
    Id: '30',
    Name: 'Russell Inv Active-Passive Balanced Model Strategy',
    LogoUrl: LogoEnum.Russell,
    RiskTolerance: RiskToleranceEnum.Moderate,
    MoneyManager: MoneyManagerEnum.Russell,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.Growth, TagEnum.Income],
    InvestmentLinks: [{
      Title: 'Russell Active-Passive Resources',
      Link: 'https://russellinvestments.com/us/fund-center/model-portfolios/hybrid-model-strategies',
    }],
  },
  {
    Id: '31',
    Name: 'Russell Inv Tax-Managed Balanced Model Strategy (Class s)',
    LogoUrl: LogoEnum.Russell,
    RiskTolerance: RiskToleranceEnum.Moderate,
    MoneyManager: MoneyManagerEnum.Russell,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.TaxEfficient],
    InvestmentLinks: [{
      Title: 'Russell Tax-Managed Resources',
      Link: 'https://russellinvestments.com/us/fund-center/model-portfolios/tax-managed-model-strategies',
    }],
  },
  {
    Id: '32',
    Name: 'Vanguard CRSP 60% Equity/ 40% Fixed Income Portfolio',
    LogoUrl: LogoEnum.Vanguard,
    RiskTolerance: RiskToleranceEnum.Moderate,
    MoneyManager: MoneyManagerEnum.Vanguard,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.Growth, TagEnum.Income],
    InvestmentLinks: [{
      Title: 'Vanguard CRSP Portfolio Resources',
      Link: 'https://advisors.vanguard.com/portfolio-construction-tools/model-portfolios',
    }],
  },
  {
    Id: '33',
    Name: 'Alphastar Core Guardian Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Conservative,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Preservation],
  },
  {
    Id: '34',
    Name: 'Alphastar Primary Guardian Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Conservative,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.FiveHundred,
    Tags: [TagEnum.Preservation],
  },
  {
    Id: '35',
    Name: 'Betashield Fundamental Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Moderate,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Thirty,
    ModelMinimum: ModelMinimumEnum.TwentyFiveThousand,
    Tags: [TagEnum.RiskMitigation],
  },
  {
    Id: '36',
    Name: 'Bond Ladder - High Yield',
    LogoUrl: LogoEnum.Invesco,
    RiskTolerance: RiskToleranceEnum.ModeratelyConservative,
    MoneyManager: MoneyManagerEnum.Invesco,
    ManagerFee: ManagerFeeEnum.Zero,
    ModelMinimum: ModelMinimumEnum.FiveThousand,
    Tags: [TagEnum.Income],
    Description: 'Our Bond Ladder models have a fixed time horizon of 5 years and are comprised of 5 equally-weighted bond ETFs, each with a target maturity of 1 to 5 years.',
    InvestmentLinks: [{
      Title: 'Invesco Resources',
      Link: 'https://www.invesco.com/us/en/insights/how-to-build-a-bond-ladder.html',
    }],
  },
  {
    Id: '37',
    Name: 'Inspire Conservative Strategy',
    LogoUrl: LogoEnum.Inspire,
    RiskTolerance: RiskToleranceEnum.Moderate,
    MoneyManager: MoneyManagerEnum.Inspire,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FiveThousand,
    Tags: [TagEnum.Preservation],
  },
  {
    Id: '38',
    Name: 'Russell Inv Active-Passive Moderate Model Strategy',
    LogoUrl: LogoEnum.Russell,
    RiskTolerance: RiskToleranceEnum.ModeratelyConservative,
    MoneyManager: MoneyManagerEnum.Russell,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.Growth, TagEnum.Income],
    InvestmentLinks: [{
      Title: 'Russell Active-Passive Resources',
      Link: 'https://russellinvestments.com/us/fund-center/model-portfolios/hybrid-model-strategies',
    }],
  },
  {
    Id: '39',
    Name: 'Vanguard CRSP 40% Equity/ 60% Fixed Income Portfolio',
    LogoUrl: LogoEnum.Vanguard,
    RiskTolerance: RiskToleranceEnum.ModeratelyConservative,
    MoneyManager: MoneyManagerEnum.Vanguard,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.Growth, TagEnum.Income],
    InvestmentLinks: [{
      Title: 'Vanguard CRSP Portfolio Resources',
      Link: 'https://advisors.vanguard.com/portfolio-construction-tools/model-portfolios',
    }],
  },
  {
    Id: '40',
    Name: 'Alphastar Core Select Reserves Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Conservative,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FiveThousand,
    Tags: [TagEnum.Preservation, TagEnum.Income],
  },
  {
    Id: '41',
    Name: 'Alphastar Primary Assurance Strategy',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Conservative,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.FiveHundred,
    Tags: [TagEnum.Preservation, TagEnum.Income],
  },
  {
    Id: '42',
    Name: 'Bond Ladder - Investment Grade',
    LogoUrl: LogoEnum.Invesco,
    RiskTolerance: RiskToleranceEnum.Conservative,
    MoneyManager: MoneyManagerEnum.Invesco,
    ManagerFee: ManagerFeeEnum.Zero,
    ModelMinimum: ModelMinimumEnum.FiveThousand,
    Tags: [TagEnum.Income],
    Description: 'Our Bond Ladder models have a fixed time horizon of 5 years and are comprised of 5 equally-weighted bond ETFs, each with a target maturity of 1 to 5 years.',
    InvestmentLinks: [{
      Title: 'Invesco Resources',
      Link: 'https://www.invesco.com/us/en/insights/how-to-build-a-bond-ladder.html',
    }],
  },
  {
    Id: '43',
    Name: 'Bond Ladder - Municipal',
    LogoUrl: LogoEnum.Invesco,
    RiskTolerance: RiskToleranceEnum.Conservative,
    MoneyManager: MoneyManagerEnum.Invesco,
    ManagerFee: ManagerFeeEnum.Zero,
    ModelMinimum: ModelMinimumEnum.FiveThousand,
    Tags: [TagEnum.Income, TagEnum.TaxEfficient],
    Description: 'Our Bond Ladder models have a fixed time horizon of 5 years and are comprised of 5 equally-weighted bond ETFs, each with a target maturity of 1 to 5 years.',
    InvestmentLinks: [{
      Title: 'Invesco Resources',
      Link: 'https://www.invesco.com/us/en/insights/how-to-build-a-bond-ladder.html',
    }],
  },
  {
    Id: '44',
    Name: 'Russell Inv Active-Passive Conservative Model Strategy',
    LogoUrl: LogoEnum.Russell,
    RiskTolerance: RiskToleranceEnum.Conservative,
    MoneyManager: MoneyManagerEnum.Russell,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.Preservation, TagEnum.Income],
    InvestmentLinks: [{
      Title: 'Russell Active-Passive Resources',
      Link: 'https://russellinvestments.com/us/fund-center/model-portfolios/hybrid-model-strategies',
    }],
  },
  {
    Id: '45',
    Name: 'Russell Inv Tax-Managed Conservative Model Strategy (Class s)',
    LogoUrl: LogoEnum.Russell,
    RiskTolerance: RiskToleranceEnum.ModeratelyConservative,
    MoneyManager: MoneyManagerEnum.Russell,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.TaxEfficient, TagEnum.Preservation, TagEnum.Income],
    InvestmentLinks: [{
      Title: 'Russell Tax-Managed Resources',
      Link: 'https://russellinvestments.com/us/fund-center/model-portfolios/tax-managed-model-strategies',
    }],
  },
  {
    Id: '46',
    Name: 'Russell Inv Tax-Managed Moderate Model Strategy (Class s)',
    LogoUrl: LogoEnum.Russell,
    RiskTolerance: RiskToleranceEnum.ModeratelyConservative,
    MoneyManager: MoneyManagerEnum.Russell,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.TaxEfficient],
    InvestmentLinks: [{
      Title: 'Russell Tax-Managed Resources',
      Link: 'https://russellinvestments.com/us/fund-center/model-portfolios/tax-managed-model-strategies',
    }],
  },
  {
    Id: '47',
    Name: 'Vanguard CRSP 100% Fixed Income Portfolio',
    LogoUrl: LogoEnum.Vanguard,
    RiskTolerance: RiskToleranceEnum.Conservative,
    MoneyManager: MoneyManagerEnum.Vanguard,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TwentyFiveThousand,
    Tags: [TagEnum.Preservation, TagEnum.Income],
    InvestmentLinks: [{
      Title: 'Vanguard CRSP Portfolio Resources',
      Link: 'https://advisors.vanguard.com/portfolio-construction-tools/model-portfolios',
    }],
  },
  {
    Id: '48',
    Name: 'Vanguard CRSP 20% Equity/ 80% Fixed Income Portfolio',
    LogoUrl: LogoEnum.Vanguard,
    RiskTolerance: RiskToleranceEnum.Conservative,
    MoneyManager: MoneyManagerEnum.Vanguard,
    ManagerFee: ManagerFeeEnum.Five,
    ModelMinimum: ModelMinimumEnum.TenThousand,
    Tags: [TagEnum.Preservation],
    InvestmentLinks: [{
      Title: 'Vanguard CRSP Portfolio Resources',
      Link: 'https://advisors.vanguard.com/portfolio-construction-tools/model-portfolios',
    }],
  },
  {
    Id: '49',
    Name: 'Van Hulzen Covered Call SMA',
    LogoUrl: LogoEnum.VanHulzen,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.VanHulzen,
    ManagerFee: ManagerFeeEnum.FortyThree,
    ModelMinimum: ModelMinimumEnum.TwoHundredFiftyThousand,
    Tags: [TagEnum.Growth, TagEnum.Income],
  },
  {
    Id: '51',
    Name: 'Retirelink 1',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Conservative,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Preservation],
  },
  {
    Id: '52',
    Name: 'Retirelink 2',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Moderate,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '53',
    Name: 'Retirelink 3',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.ModeratelyAggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '54',
    Name: 'Alphastar Growth Stock',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.TwentyFive,
    ModelMinimum: ModelMinimumEnum.TwentyFiveThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '55',
    Name: 'Alphastar Dividend Stock',
    LogoUrl: LogoEnum.Alphastar,
    RiskTolerance: RiskToleranceEnum.ModeratelyAggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.TwentyFive,
    ModelMinimum: ModelMinimumEnum.TwentyFiveThousand,
    Tags: [TagEnum.Growth, TagEnum.Income],
  },
];

export const EvolvInvestmentStrategies: InvestmentStrategyModel[] = [
  {
    Id: '1',
    Name: 'Evolv Core Global Equity Strategy',
    LogoUrl: LogoEnum.Evolv,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '2',
    Name: 'Evolv Core International Equity Strategy',
    LogoUrl: LogoEnum.Evolv,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '3',
    Name: 'Evolv Core US Equity Strategy',
    LogoUrl: LogoEnum.Evolv,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '6',
    Name: 'Evolv Core Patriot Strategy',
    LogoUrl: LogoEnum.Evolv,
    RiskTolerance: RiskToleranceEnum.Aggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '14',
    Name: 'Evolv Core Dynamic Strategy',
    LogoUrl: LogoEnum.Evolv,
    RiskTolerance: RiskToleranceEnum.ModeratelyAggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '15',
    Name: 'Evolv Core Opportunity Strategy',
    LogoUrl: LogoEnum.Evolv,
    RiskTolerance: RiskToleranceEnum.ModeratelyAggressive,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth],
  },
  {
    Id: '21',
    Name: 'Evolv Core Balanced Strategy',
    LogoUrl: LogoEnum.Evolv,
    RiskTolerance: RiskToleranceEnum.ModeratelyConservative,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth, TagEnum.Income],
  },
  {
    Id: '22',
    Name: 'Evolv Core Enhanced Income Strategy',
    LogoUrl: LogoEnum.Evolv,
    RiskTolerance: RiskToleranceEnum.Moderate,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Income],
  },
  {
    Id: '23',
    Name: 'Evolv Core Fundamental Strategy',
    LogoUrl: LogoEnum.Evolv,
    RiskTolerance: RiskToleranceEnum.Moderate,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Growth, TagEnum.Income],
  },
  {
    Id: '33',
    Name: 'Evolv Core Guardian Strategy',
    LogoUrl: LogoEnum.Evolv,
    RiskTolerance: RiskToleranceEnum.Conservative,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FifteenThousand,
    Tags: [TagEnum.Preservation],
  },
  {
    Id: '40',
    Name: 'Evolv Core Select Reserves Strategy',
    LogoUrl: LogoEnum.Evolv,
    RiskTolerance: RiskToleranceEnum.Conservative,
    MoneyManager: MoneyManagerEnum.Alphastar,
    ManagerFee: ManagerFeeEnum.Ten,
    ModelMinimum: ModelMinimumEnum.FiveThousand,
    Tags: [TagEnum.Preservation, TagEnum.Income],
  },
];

export const AdditionalOfferings: AdditionalOfferingModel[] = [
  {
    Id: '101',
    Name: 'Structured Notes',
    LogoUrls: [ LogoEnum.Luma, LogoEnum.Alphastar ],
    Description: 'Structured notes allow investors to commit capital for a specific time period in exchange for potentially high returns, high income, or capital preservation during times of market volatility.',
    AdditionalDescription: 'Contact your sales team to learn more about structured notes through our partner LUMA.',
  },
  {
    Id: '102',
    Name: 'Fee-Based Insurance Products',
    LogoUrls: [ LogoEnum.Alphastar ],
    Description: 'Insurance products such as fixed-index annuities (FIA) and registered index-linked annuities (RILA) and others can limit exposure to downside risk while providing opportunity for growth and income.',
    AdditionalDescription: 'Contact your sales team to learn more about how Alphastar can help with fee-based insurance.',
  },
  {
    Id: '103',
    Name: 'DST',
    LogoUrls: [ LogoEnum.Madrona ],
    Description: 'A Delaware Statutory Trust allows accredited investors to exchange a single property for a diversified portfolio of real estate holdings, benefiting from tax efficiencies.',
    AdditionalDescription: 'Contact your sales team to learn more about DSTs through our partner Madrona Financial Services.',
  },
  {
    Id: '104',
    Name: 'Alternative Investments',
    LogoUrls: [ LogoEnum.AlternativeInvestment ],
    Description: 'Alternative investments include hedge funds, private equity, private credit, and other asset classes targeted to high-net-worth clients. They can offer potentially high returns, high income, low volatility and/or low correlation to traditional asset classes.',
    AdditionalDescription: 'For more information about alternative investments, please speak to the Alphastar team about accessing the CAIS platform.',
  },
];