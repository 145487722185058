import { RequestModel } from 'src/app/core/clients/generated/client';
import { TableHeader } from 'src/app/shared/helpers/table.helpers';
import { FilterCheckbox, RequestTrackerTypeEnum } from '../models/request-tracker-models';

export const RequestTrackerHeaders: TableHeader<RequestModel>[] = [
  {
    DisplayName: 'Advisor',
    ColumnName: ['advisor'],
    WidthClass: 'min-w-196 w-196',
  },
  {
    DisplayName: 'Client',
    ColumnName: ['client'],
    WidthClass: 'min-w-196 w-196',
    CompareFn: (a: RequestModel, b: RequestModel, order: 1|-1):number => {
      const valA = a.clients ? a.clients.map(c => c.lastName.concat(c.firstName)).join(',') : '';
      const valB = b.clients ? b.clients.map(c => c.lastName.concat(c.firstName)).join(',') : '';
      return (valA === valB ? 0 : valA > valB ? 1 : -1) * order;
    },
  },
  {
    DisplayName: 'Account',
    ColumnName: ['investmentAccountModel', 'custodian'],
    WidthClass: 'min-w-196 w-196',
  },
  {
    DisplayName: 'Type',
    ColumnName: ['category'],
    WidthClass: 'min-w-196 w-196',
  },
  {
    DisplayName: 'Updated On',
    ColumnName: ['statusDate'],
    WidthClass: 'min-w-196 w-196',
  },
  {
    DisplayName: 'Status',
    ColumnName: ['status'],
    WidthClass: 'min-w-196 w-196',
  },
];

export class RequestTrackerFilter {
  Status = '';
  Advisors: FilterCheckbox[] = [];
  Types: FilterCheckbox[] = [
    {
      Name: RequestTrackerTypeEnum.Contribution,
      Value: RequestTrackerTypeEnum.Contribution,
      Checked: false,
      Order: 1,
    },
    {
      Name: RequestTrackerTypeEnum.Distribution,
      Value: RequestTrackerTypeEnum.Distribution,
      Checked: false,
      Order: 2,
    },
    {
      Name: RequestTrackerTypeEnum.Reallocation,
      Value: RequestTrackerTypeEnum.Reallocation,
      Checked: false,
      Order: 3,
    },
    {
      Name: RequestTrackerTypeEnum.CloseAccount,
      Value: RequestTrackerTypeEnum.CloseAccount,
      Checked: false,
      Order: 4,
    },
    {
      Name: RequestTrackerTypeEnum.PlanningDesk,
      Value: RequestTrackerTypeEnum.PlanningDesk,
      Checked: false,
      Order: 5,
    },
    {
      Name: RequestTrackerTypeEnum.OrionServiceRequest,
      Value: RequestTrackerTypeEnum.OrionServiceRequest,
      Checked: false,
      Order: 6,
    },
    {
      Name: RequestTrackerTypeEnum.GeneralAccount,
      Value: RequestTrackerTypeEnum.GeneralAccount,
      Checked: false,
      Order: 7,
    },
  ].sort((a, b) => a.Order < b.Order ? -1 : a.Order > b.Order ? 1 : 0);
}