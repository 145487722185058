@if (account && workflow) {
  <form @fadeIn [formGroup]="form" (ngSubmit)="onSubmit()"
    class="flex flex-col w-full h-full overflow-hidden">
    <app-account-request-modal-header #header [title]="workflow.title" [formService]="reAllocationFormService"
    [account]="account" (closeRequested)="closeRequest()"></app-account-request-modal-header>
    @if (isSubmitting) {
      <div class="flex-grow w-full loader-wrap" @fadeIn>
        <div class="loader-sm is-blue"></div>
      </div>
    }
    @if (!isLoading && !isSubmitting && !isSubmitted) {
      <section class="flex flex-col flex-grow h-full overflow-auto">
        @if (reAllocationFormService.currentStep === accountUpdateStepEnum.step1) {
          <app-allocation-step class="h-full"
            [investmentRequestFormService]="reAllocationFormService" [parentForm]="form.controls.allocationConfiguration"
            [initialAmount]="reAllocationFormService.initialAmount"
          [requestType]="requestTypeEnum.reallocation"></app-allocation-step>
        }
        @if (reAllocationFormService.currentStep === accountUpdateStepEnum.step2) {
          <section class="flex flex-col p-30 h-full gap-y-20"
            [formGroup]="form.controls.allocationConfiguration">
            <!--<app-solicitation-options [solicitationFormGroup]="form.controls.solicitation"></app-solicitation-options>-->
            <section class="flex flex-col h-full gap-y-20">
              <label class="text-base font-bold text-gray-dark required-label" for="contribution-uploads">Please upload any
              required documents below:</label>
              <app-message-notification>
                <span>If changes were made to a model's account fee, <a target="_blank" href="/api/wistia/download?form=feechange2022">please fill out
                the Fee Change Request Form</a> and upload the document below. <strong>This form is required for any fee changes.</strong></span>
              </app-message-notification>
              <app-file-dropper (fileChangeEvent)="uploadFiles($event)" class="mt-5"
              [value]="reAllocationFormService.files"></app-file-dropper>
            </section>
          </section>
        }
        @if (reAllocationFormService.currentStep === accountUpdateStepEnum.step3) {
          <app-review-step
            [investmentRequestFormService]="reAllocationFormService"
          [requestType]="requestTypeEnum.reallocation"></app-review-step>
        }
      </section>
    }
    @if (!isSubmitting && isSubmitted) {
      <section class="flex flex-col flex-grow h-full overflow-auto">
        <app-complete [complete]="isComplete" [requestType]="requestTypeEnum.reallocation" [account]="account" [crmRequestID]="crmRequestID"></app-complete>
      </section>
    }
    @if (!showCustomHoldings && !isComplete) {
      <footer>
        @if (reAllocationFormService.currentStep === accountUpdateStepEnum.step1) {
          <button [disabled]="isSubmitting" type="button"
            class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center" (click)="cancelRequest()"
          >Back</button>
        }
        @if ((reAllocationFormService.currentStep > accountUpdateStepEnum.step1)) {
          <button [disabled]="isSubmitting" type="button"
            class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
          (click)="reAllocationFormService.goToPreviousStep()">Back</button>
        }
        @if ((reAllocationFormService.currentStep <= (pages.length - 1))) {
          <button [disabled]="isSubmitting" type="button" class="m-10 ml-auto button is-primary is-lg flex justify-center"
            (click)="reAllocationFormService.goToNextStep(pages[reAllocationFormService.currentStep - 1].formGroup, pages.length)"
          >Next</button>
        }
        @if (reAllocationFormService.currentStep === accountUpdateStepEnum.step3) {
          <button [disabled]="isSubmitting" type="submit" class="m-10 ml-auto button is-primary is-lg flex justify-center"
            >{{isSubmitting ? "Submitting" : "Submit"}}</button>
        }
      </footer>
    }
  </form>
}