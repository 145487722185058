/* eslint-disable @typescript-eslint/no-unused-vars */
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountModel, AccountSearchListModel, AgentsClient, InvestmentAccountsClient } from 'src/app/core/clients/generated/client';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { fadeIn } from 'src/app/shared/constants/animations';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalData } from 'src/app/shared/modals/confirmation-modal/modal-data.models';
import { Account } from '../../../accounts/models/account.models';
import { RequestTypeEnum } from 'src/app/portal/features/account-request-workflows/shared/enums/account-update.enum';
import { AccountWorkflowNavModel, SolicitationOptionModel } from 'src/app/portal/features/account-request-workflows/shared/models/workflow.models';
import { WorkflowNavigationService } from 'src/app/portal/features/account-request-workflows/shared/services/workflow-navigation.service';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { Permission } from 'src/app/core/clients/generated/client';
import { PermissionsService } from 'src/app/core/auth/permissions.service';

@Component({
  animations: [fadeIn],
  selector: 'app-account-update-main-modal',
  templateUrl: './account-update-main-modal.component.html',
})
export class AccountUpdateMainModalComponent implements OnInit, AfterViewInit {
  perm = Permission;
  accounts: AccountSearchListModel[] = [];
  advisorIds: number[] = [];
  filteredAccounts!: AccountSearchListModel[];
  searchLoading = true;
  modalLoading = false;
  requestTypeEnum = RequestTypeEnum;
  searchField = this.fb.group({
    searchInput: this.fb.nonNullable.control<string>('')
  });
  selectedAccountId?: number;
  selectedAccountValue?: number;
  selectedAccountStatus?: string;
  account?: AccountModel;
  selectedWorkflow: RequestTypeEnum = this.requestTypeEnum.accountSelection;
  workflowNavItems: AccountWorkflowNavModel[] = [];
  solicitationOptionItems: SolicitationOptionModel[] = [];
  selectedWorkflowNavItem: AccountWorkflowNavModel | undefined = undefined;
  selectedSolicitationOption: SolicitationOptionModel | undefined = undefined;
  private noAccountValueMessage = 'You can not perform this type of request on an account with a value of $0. Please contact Alphastar for additional support.';
  private closedAccountMessage = 'You can not perform this type of request on an account that has been closed. Please contact Alphastar for additional support.';
  private errorMessage = 'An error occurred retrieving your account. Please try again.';

  constructor(
    private dialog: MatDialog,
    private agentsApiService: AgentsClient,
    public dialogRef: MatDialogRef<AccountUpdateMainModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Account,
    private fb: FormBuilder,
    private investmentAccountApi: InvestmentAccountsClient,
    private snackbarService: SnackbarService,
    private _workflowNavigationService: WorkflowNavigationService,
    private _permissionService: PermissionsService,
    private snackbar: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.modalLoading = true;
    this.workflowNavItems = this._workflowNavigationService.workflowNavList;

    if (this._permissionService.hasOnePermission(Permission.ViewOrionElementRequests)) {
      this.selectedWorkflowNavItem = this.workflowNavItems.find(x => x.order === RequestTypeEnum.reallocation);
    }

    this.solicitationOptionItems = this._workflowNavigationService._workflowSilicationOptionsList;
    this.agentsApiService.getParentAgents(true)
      .subscribe({
        next: (results) => {
          this.advisorIds = results.map(advisor => {
            return advisor.agentID;
          });
        },
        error: () => {
          this.snackbarService.openSnackbar('There was an error loading advisors', ToastClassEnum.warning);
        },
        complete: () => {
          if (this.data) this.prefillSearchInput(this.data);
          this.modalLoading = false;
        }
      });

    this.searchField.controls.searchInput.valueChanges.pipe(
      filter(searchText => !!searchText && searchText.length > 2),
      tap(() => this.searchLoading = true), //set loading to true as soon as the filter criteria are satisfied
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((searchText: string, index: number) => {
        return this.investmentAccountApi.searchAcmAccountsForAgentIds(this.advisorIds, searchText); //only complete search if input hasn't changed to prevent unnecessary api calls
      }))
      .subscribe({
        next: (results: AccountSearchListModel[]) => {
          this.accounts = results;
          this.searchLoading = false;
        },
        error: () => {
          this.searchLoading;
          this.snackbarService.openSnackbar('Error retrieving Accounts.', ToastClassEnum.warning);
        }
      });
  }

  ngAfterViewInit(): void {
    const width = '80rem';
    const height = '84rem';
    this.dialogRef.updateSize(width, height);
  }

  /** Prefill search input with data from selected account from account list
   * @param account
  */
  prefillSearchInput(account: Account): void {
    if (account.InvestmentAccountId) {
      const accountNumberString = account.AccountNumber ?? 'No Account Number';
      this.selectedAccountId = account.InvestmentAccountId;
      this.selectedAccountValue = account.Value ?? undefined;
      this.selectedAccountStatus = account.Status ?? undefined;
      this.searchField.controls.searchInput.setValue(`${account.Custodian} - ${accountNumberString} - ${account.Type}`);
    }
  }

  resetEvent(): void {
    if (this.selectedWorkflow !== this.requestTypeEnum.accountSelection) {
      this.selectedWorkflow = this.requestTypeEnum.accountSelection;
    }
  }

  /**clear search field or reset search results when an account is selected */
  resetSearch(): void {
    this.searchField.controls.searchInput.reset();
    this.selectedAccountId = undefined;
    this.selectedAccountValue = undefined;
  }

  selectAccount(account: AccountSearchListModel): void {

    this.investmentAccountApi.getInvestmentAccount(account.investmentAccountID)
      .subscribe({
        next: (account) => {
          this.selectedAccountId = account.investmentAccountID;
          this.selectedAccountValue = account.accountValue;
          this.selectedAccountStatus = account.investmentAccountStatus;
        },
        error: () => {
          // not expected to happen - only on random network errors
          this.snackbar.openSnackbar('There was an error processing your request.', ToastClassEnum.warning);
        }
      });
  }

  selectNavItem(navItem: AccountWorkflowNavModel | undefined, solicitationItem: SolicitationOptionModel | undefined): void {

    if (!this.selectedAccountId) return;

    if (navItem === undefined) {
      if (!this.isGeneralRequest){
        if (solicitationItem === undefined)
          return;
      }
      return;
    }

    if (this.accountIsClosed()) {
      this.openConfirmationModal(this.closedAccountMessage, 'Okay', false);
      return;
    }

    if (this.isDistributionOrReallocationRequest(navItem) && !this.hasAccountValue()) {
      this.openConfirmationModal(this.noAccountValueMessage, 'Okay', false);
      return;
    }

    this.modalLoading = true;
    this.selectedWorkflowNavItem = navItem;
    this.selectedSolicitationOption = solicitationItem;
    this.selectedWorkflow = navItem.order;
    this.investmentAccountApi.getInvestmentAccount(this.selectedAccountId)
      .subscribe({
        next: (account) => {
          this.account = account;
        },
        error: () => {
          this.openConfirmationModal(this.errorMessage, 'Close', true);
          this.selectedWorkflow = this.requestTypeEnum.accountSelection;
        }
      }).add(() => this.modalLoading = false);
  }

  openConfirmationModal(message: string, buttonText: string, isWarning: boolean): void {
    this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
      data: {
        message: message,
        confirmationButtonText: buttonText,
        isWarning: isWarning,
        hideCancelButton: true
      }
    });
  }

  private isDistributionOrReallocationRequest = (navItem: AccountWorkflowNavModel): boolean => [RequestTypeEnum.distribution, RequestTypeEnum.reallocation].includes(navItem.order);
  private isGeneralRequest = (navItem: AccountWorkflowNavModel): boolean => [RequestTypeEnum.generalAccount].includes(navItem.order);
  private hasAccountValue = (): boolean => !!this.selectedAccountValue;
  private accountIsClosed = (): boolean => this.selectedAccountStatus === 'Closed';
}
