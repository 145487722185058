import { Component, Input, OnInit } from '@angular/core';
import { GeneralAccountReviewModel } from '../models/general-account-review.model';
import { AdvisorMessageFormGroup } from '../../../../../shared/models/files-messages.model';
import { DeliveryMethodEnum } from '../../shared/enums/delivery-methods.enum';

@Component({
  selector: 'app-general-account-review-step',
  templateUrl: './general-account-review-step.component.html',
})
export class GeneralAccountReviewStepComponent implements OnInit {
  @Input() advisorMessageForm?: AdvisorMessageFormGroup;
  reviewModel?: GeneralAccountReviewModel;
  deliveryMethodEnum = DeliveryMethodEnum;

  ngOnInit(): void {
    this.reviewModel = this.getReviewModel();
  }

  getReviewModel(): GeneralAccountReviewModel {
    return {
      notes: this.advisorMessageForm?.value.message as string,
      files: this.advisorMessageForm?.value.files as File[],
    };
  }
}
