import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AccountUpdateStepModel, AccountWorkflowNavModel } from '../shared/models/workflow.models';
import { AccountMessageModel, AccountModel, AccountSearchListModel, AccountUploadsClient, AgentModel, AgentsClient, FileParameter, InvestmentAccountsClient } from 'src/app/core/clients/generated/client';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { AccountMessagePostModel, AdvisorMessageFormGroup, Recipient } from '../../../../shared/models/files-messages.model';
import { Account } from '../../accounts/models/account.models';
import { formatPercent } from '@angular/common';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalData } from 'src/app/shared/modals/confirmation-modal/modal-data.models';
import { RequestExitConfirmationData } from '../shared/constants/workflow-navigation.constants';
import { AccountUpdateStepEnum, RequestTypeEnum } from '../shared/enums/account-update.enum';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FileDropperComponent } from 'src/app/shared/components/file-dropper/file-dropper.component';
import { catchError, forkJoin, Observable, Subscription } from 'rxjs';
import { fadeIn } from 'src/app/shared/constants/animations';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { recipientsListData } from 'src/app/shared/constants/files-messages.constants';

@Component({
  selector: 'app-general-account-request',
  templateUrl: './general-account-request.component.html',
  animations: [fadeIn],
})
export class GeneralAccountRequestComponent implements OnInit {
  @Output() canceled = new EventEmitter();
  @Input() account?: AccountModel;
  @Input() workflow?: AccountWorkflowNavModel;
  @ViewChild(FileDropperComponent) fileDropper!: FileDropperComponent;
  @ViewChild('fileUpload') fileUpload!: ElementRef<HTMLInputElement>;
  crmRequestID?: number;
  requestTypeEnum = RequestTypeEnum;
  isSubmitting = false;
  isSubmitted = false;
  isComplete = false;
  accountUpdateStepEnum = AccountUpdateStepEnum;
  currentStep = this.accountUpdateStepEnum.step1;
  messageSubscription?: Subscription;
  filesSubscription?: Subscription;
  accountsList?: Observable<AccountSearchListModel[]>;
  recipientsList: Recipient[] = recipientsListData;
  loggedInAdvisor?: AgentModel;
  advisorIds?: number[];
  selectedAccount: AccountSearchListModel | null = null;
  submitCount = 0;
  formHasMessage = false;
  formHasFiles = false;
  advisorMessageFormGroup = this.fb.group({
    //account: [null, [Validators.required]],
    account: [null],
    message: [null, [Validators.required]],
    files: [null, [Validators.required]],
  }) as AdvisorMessageFormGroup;

  pages: AccountUpdateStepModel[] = [
    {
      formGroup: this.advisorMessageFormGroup,
      value: this.accountUpdateStepEnum.step1,
    },
    {
      formGroup: this.advisorMessageFormGroup,
      value: this.accountUpdateStepEnum.step2,
    },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<GeneralAccountRequestComponent>,
    private investmentAccountApi: InvestmentAccountsClient,
    private snackbarService: SnackbarService,
    private accountUploadsClient: AccountUploadsClient,
    private agentsClient: AgentsClient,
  ) { }

  ngOnInit(): void {
    this.messageSubscription = this.advisorMessageFormGroup.controls.message.valueChanges.subscribe(newValue => {
      if (newValue && typeof newValue === 'string') {
        this.validateFileAndMessageInputs(newValue);
      } else {
        this.validateFileAndMessageInputs('');
      }
    });
    this.filesSubscription = this.advisorMessageFormGroup.controls.files.valueChanges.subscribe(newValue => {
      if (<File[]>newValue) {
        this.validateFileAndMessageInputs(newValue as File[]);
      } else {
        this.validateFileAndMessageInputs([]);
      }
    });

    if (this.account)
      this.getData(this.account);
  }

  private validateFileAndMessageInputs(value: string | File[]): void {
    if (typeof value === 'string') {
      if (value.length) {
        this.advisorMessageFormGroup.controls.files.clearValidators();
        this.formHasMessage = true;
      }
    } else {
      if (value.length > 0) {
        this.advisorMessageFormGroup.controls.message.clearValidators(
        );
        this.formHasFiles = true;
      }
    }

    if (!this.formHasMessage && !this.formHasFiles) {
      this.advisorMessageFormGroup.controls.files.addValidators(Validators.required);
      this.advisorMessageFormGroup.controls.message.addValidators(Validators.required);
    }
    // The "emitEvent: false" statement is used to avoid a circular change detection cycle.
    this.advisorMessageFormGroup.controls.files.updateValueAndValidity({ onlySelf: false, emitEvent: false });
    this.advisorMessageFormGroup.controls.message.updateValueAndValidity({ onlySelf: false, emitEvent: false });
  }

  private getData(account: AccountModel): void {
    const loggedInAdvisorApiCall: Observable<AgentModel> = this.agentsClient.getAgentSelf().pipe(catchError(error => {
      throw error;
    }));
    const accountApiCall: Observable<AccountModel> =  this.investmentAccountApi.getInvestmentAccount(account.investmentAccountID as number).pipe(catchError(error => {
      throw error;
    }));
    const promises = {
      loggedInAdvisorResult: loggedInAdvisorApiCall,
      accountResult: accountApiCall
    };
    forkJoin(promises)
      .subscribe({
        next: (result) => {
          this.loggedInAdvisor = result.loggedInAdvisorResult;
          this.account = result.accountResult;
        },
        error: () => {
          this.snackbarService.openSnackbar('Error retrieving information. Please try again later.', ToastClassEnum.warning);
        },
        complete: () => {
          this.advisorMessageFormGroup.controls.account.patchValue(this.account);
        },
      });
  }

  convertInvestmentAccountToAccount(account: AccountModel): Account {
    return {
      Advisors: account.advisors.map(a => {
        return {
          AdvisorId: a.agentID,
          Crd: a.crd,
          FirstName: a.firstName,
          LastName: a.lastName,
        };
      }),
      Clients: account.clients.map(c => {
        return {
          Id: c.id,
          FirstName: c.firstName,
          LastName: c.lastName,
        };
      }),
      Type: account.investmentAccountType,
      Model: account.investmentAccountModel,
      Custodian: account.custodian,
      CustodianId: account.custodianID as number,
      Value: account.accountValue as number,
      CashValue: account.cashValue as number,
      OpenedDate: account.openedOn as Date,
      Fee: GeneralAccountRequestComponent.calculateAccountFee(account),
      Status: account.investmentAccountStatus,
      IsInvestmentAccount: true,
      AccountNumber: account.accountNumber,
      Carrier: null,
      Product: null,
      InsuranceAccountId: null,
      InvestmentAccountId: account.investmentAccountID,
      Sleeves: account.sleeves,
      ShowSleeves: false,
      TDSchwabAccountNumbers: account.tdSchwabAccountNumbers,
    };
  }

  /** Submits the form data if it is valid. */
  onSubmit(): void {
    if (this.account) {
      if (this.advisorMessageFormGroup.invalid) {
        this.advisorMessageFormGroup.markAllAsTouched();
        return;
      } else {
        this.isSubmitting = true;
        this.postRequest();
      }
    }
  }

  postRequest(): void {
    if (this.advisorMessageFormGroup.invalid) {
      this.advisorMessageFormGroup.markAllAsTouched();
      return;
    }

    const accountMessageModel = this.createPostModel();
    this.accountUploadsClient.postAccountMessage(accountMessageModel.accountMessageModel, accountMessageModel.files).subscribe({
      next: (result) => {
        //used to pass to complete page for survey
        this.crmRequestID = result;
      },
      error: () => {
        this.submitCount++;
        // for work around on sometimes the first request failing submitting to the crm.
        if (this.submitCount < 2) {
          console.log('received error, retrying in 2 seconds');
          setTimeout(() => {
            this.onSubmit();
          }, 2000);
        } else {
          this.isSubmitting = false;
          this.isSubmitted = true;
          this.isComplete = false;
          this.submitCount = 0;
        }
      },
      complete: () => {
        this.isSubmitting = false;
        this.isSubmitted = true;
        this.isComplete = true;
      }
    });
  }

  private createPostModel(): AccountMessagePostModel {

    const accountMessageModel: AccountMessageModel = {
      //recipientID: this.advisorMessageFormGroup.value.recipient.recipientId,//
      recipientID: 1, //hard coding Ops from recipientsListData.
      agentID: this.loggedInAdvisor?.agentID ?? 0,
      accountID: this.advisorMessageFormGroup.value.account?.investmentAccountID ?? 0,
      notes: this.advisorMessageFormGroup.value.message,
      onBehalfOfRequest: undefined,
    };

    const advisorMessagePostModel: AccountMessagePostModel = {
      accountMessageModel: accountMessageModel,
      files: this.createFileParameter(this.advisorMessageFormGroup.value.files)
    };

    return advisorMessagePostModel;
  }

  /** Transforms an array of type File into an array of type FileParameter. */
  private createFileParameter(files: File[]): FileParameter[] {
    return files.map(file => {
      return {
        fileName: file.name,
        data: file,
      };
    });
  }

  /** Extracts files from a file upload event and adds them to the form post model.
   * The maximum size for a single file is 5MB or less.
   * The file type must be one of the following: pdf, jpg, jpeg, png.
   */
  uploadFiles(files: File[]): void {
    this.advisorMessageFormGroup.controls.files.patchValue(files);
  }

  closeRequest(): void {
    if (this.isSubmitted && this.isComplete) {
      this.dialogRef.close(false);
      return;
    }

    const confirmationDialog = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
      data: RequestExitConfirmationData
    });

    confirmationDialog.afterClosed().subscribe(res => {
      if (res) this.dialogRef.close(false);
      else return;
    });
  }

  cancelRequest(): void {
    const confirmationDialog = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
      data: RequestExitConfirmationData
    });

    confirmationDialog.afterClosed().subscribe(res => {
      if (res) {
        this.canceled.emit();
      } else return;
    });
  }

  previousStep(): void {
    if (this.currentStep > this.accountUpdateStepEnum.step1) {
      this.currentStep--;
    }
  }

  nextStep(formGroup: UntypedFormGroup): void {
    if (formGroup.invalid) {
      formGroup.markAllAsTouched();
    } else {
      if ((this.currentStep < this.accountUpdateStepEnum.step2)) {
        this.currentStep++;
      }
    }
  }

  private static calculateAccountFee(account: AccountModel): string {
    if (account.totalFee === 0) return '---';
    if (!account.sleeves.length) return formatPercent(account.totalFee ?? 0, 'en-US', '1.2');
    if (account.sleeves.every(a => a.accountTotalFee === account.sleeves[0].accountTotalFee)) // check if all sleeves have same fee
      return formatPercent(account.sleeves[0].accountTotalFee, 'en-US', '1.2');
    return 'Multiple';
  }
}