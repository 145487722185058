<section class="flex flex-col p-10 gap-y-10">
  @if (model?.VideoAssets?.length) {
    <h2 class="p-20 pb-0 text-lg font-bold">{{model?.ShowName ? model?.Name : 'Videos'}}</h2>
    <section class="flex flex-wrap w-full">
      @for (asset of model?.VideoAssets; track asset) {
        <div class="w-1/2 p-20 lg:w-1/4 md:w-1/3">
          <app-development-center-wistia-media class="flex flex-col justify-center" [model]="asset"></app-development-center-wistia-media>
        </div>
      }
    </section>
  }
  @if (model?.FileAssets?.length) {
    <h2 class="p-20 pb-0 text-lg font-bold">{{model?.ShowName ? model?.Name : 'Downloads'}}</h2>
    <section class="flex flex-wrap w-full">
      @for (asset of model?.FileAssets; track asset; let index = $index) {
        <div class="w-1/2 p-20 lg:w-1/4 md:w-1/3">
          <app-development-center-wistia-media class="min-h-40" [model]="asset"></app-development-center-wistia-media>
        </div>
      }
    </section>
  }
</section>