import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountModel, InvestmentAccountsClient, RequestModel } from 'src/app/core/clients/generated/client';
import { fadeIn } from 'src/app/shared/constants/animations';
import { OrderDirection, Table } from 'src/app/shared/helpers/table.helpers';
import { virtualScrolloffsetTranslate } from 'src/app/shared/helpers/virtual-scroll-offset-translate.helper';
import { RequestTrackerHeaders } from '../../constants/request-tracker.constants';
import { RequestTrackerDetailModalComponent } from '../../modals/request-tracker-detail-modal/request-tracker-detail-modal.component';
import { Permission } from 'src/app/core/clients/generated/client';
import { Account } from '../../../accounts/models/account.models';
import { formatPercent } from '@angular/common';

@Component({
  animations: [fadeIn],
  selector: 'app-request-tracker-list',
  templateUrl: './request-tracker-list.component.html',
})
export class RequestTrackerListComponent implements OnChanges {
  @ViewChild(CdkVirtualScrollViewport, { static: false }) public viewPort?: CdkVirtualScrollViewport;
  @Input() requests: RequestModel[] = [];
  @Input() sortDirection: OrderDirection = 'desc';
  @Input() filterStatus = '';
  //columnName: string[] = ['message'];
  isLoading = true;
  reverseOrder = false;
  table: Table<RequestModel> = new Table<RequestModel>(RequestTrackerHeaders, 4);
  perm = Permission;

  constructor (
    private dialog: MatDialog,
    private investmentAccountApi: InvestmentAccountsClient,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['requests']?.isFirstChange()) {
      this.table = new Table<RequestModel>(RequestTrackerHeaders, this.filterStatus === 'Open' ? 5 : 4);
      this.table.setBody(this.requests);
      this.table.setOrderDirection(this.sortDirection);
      this.isLoading = false;
    }
  }

  // This get the offset amount of the entire virtual scroll viewport from the top in pixel.
  // We use this to manually sticky the header back to the top position in the HTML since virtual scroll messes with sticky headers
  public get offsetTranslate(): string {
    return virtualScrolloffsetTranslate(this.viewPort);
  }

  openRequestDetailModal(model: RequestModel): void {
    const ref = this.dialog.open<RequestTrackerDetailModalComponent, RequestModel>(RequestTrackerDetailModalComponent, {
      maxHeight: '90rem',
      data: model
    });

    ref.afterClosed().subscribe(result => {
      if (result && result.openMessage) {
        //this.openFileMessageUploadModal(model);
      }
    });
  }

  convertInvestmentAccountToAccount(account: AccountModel): Account {
    return {
      Advisors: account.advisors.map(a => {
        return {
          AdvisorId: a.agentID,
          Crd: a.crd,
          FirstName: a.firstName,
          LastName: a.lastName,
        };
      }),
      Clients: account.clients.map(c => {
        return {
          Id: c.id,
          FirstName: c.firstName,
          LastName: c.lastName,
        };
      }),
      Type: account.investmentAccountType,
      Model: account.investmentAccountModel,
      Custodian: account.custodian,
      CustodianId: account.custodianID as number,
      Value: account.accountValue as number,
      CashValue: account.cashValue as number,
      OpenedDate: account.openedOn as Date,
      Fee: RequestTrackerListComponent.calculateAccountFee(account),
      Status: account.investmentAccountStatus,
      IsInvestmentAccount: true,
      AccountNumber: account.accountNumber,
      Carrier: null,
      Product: null,
      InsuranceAccountId: null,
      InvestmentAccountId: account.investmentAccountID,
      Sleeves: account.sleeves,
      ShowSleeves: false,
      TDSchwabAccountNumbers: account.tdSchwabAccountNumbers,
    };
  }

  private static calculateAccountFee(account: AccountModel): string {
    if (account.totalFee === 0) return '---';
    if (!account.sleeves.length) return formatPercent(account.totalFee ?? 0, 'en-US', '1.2');
    if (account.sleeves.every(a => a.accountTotalFee === account.sleeves[0].accountTotalFee)) // check if all sleeves have same fee
      return formatPercent(account.sleeves[0].accountTotalFee, 'en-US', '1.2');
    return 'Multiple';
  }
}