import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AcmMediaProject, WistiaClient } from 'src/app/core/clients/generated/client';
import { WistiaProjectsEnum } from 'src/app/shared/enums/wistia.enum';
import { getWistiaSection } from 'src/app/shared/helpers/wistiaSection.helpers';
import { DevelopmentCenterWistiaSection, DevelopmentCenterWistiaSectionEnum } from '../../models/development-center.models';
import { Permission } from 'src/app/core/clients/generated/client';
import { fadeIn } from 'src/app/shared/constants/animations';
import { PermissionsService } from 'src/app/core/auth/permissions.service';

@Component({
  animations: [fadeIn],
  selector: 'app-alphastar-content-library-page',
  templateUrl: './alphastar-content-library-page.component.html',
})
export class AlphastarContentLibraryPageComponent implements OnInit {
  practiceManagementSection?: DevelopmentCenterWistiaSection;
  clientEngagementSection?: DevelopmentCenterWistiaSection;
  marketingCollateralSection?: DevelopmentCenterWistiaSection;
  isLoading = false;
  perm = Permission;
  wistiaProject?: AcmMediaProject;
  wistiaSections: DevelopmentCenterWistiaSection[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private wistiaApiService: WistiaClient,
    private _permissionsService: PermissionsService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.titleService.setTitle(this.activatedRoute.snapshot.data['title'] + ' | Alphastar Portal');

    this.wistiaApiService.getProjectAssets(WistiaProjectsEnum.DevelopmentCenter).subscribe({
      next: result => {
        this.wistiaProject = result;
        const practiceManagementSection: DevelopmentCenterWistiaSection | undefined = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.PracticeManagement, true);
        if (practiceManagementSection) {
          this.wistiaSections.push(practiceManagementSection);
        }
        const clientEngagementSection: DevelopmentCenterWistiaSection | undefined = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.ClientEngagement, true);
        if (clientEngagementSection) {
          this.wistiaSections.push(clientEngagementSection);
        }
        const marketingCollateralSection: DevelopmentCenterWistiaSection | undefined = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.MarketingCollateral,  true);
        if (marketingCollateralSection) {
          this.wistiaSections.push(marketingCollateralSection);
        }
      }
    });
  }
}
