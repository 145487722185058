import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthenticationGuardService } from '../core/services/guards/authentication-guard/authentication-guard.service';
import { PermissionsGuardService } from '../core/services/guards/permissions-guard/permissions-guard.service';
import { CustomRoutes } from '../shared/models/custom-route.model';
import { AccountsPageComponent } from './features/accounts/pages/accounts-page/accounts-page.component';
import { DevelopmentCenterPageComponent } from './features/development-center/pages/development-center-page/development-center-page.component';
import { FinishStrongDetailsPageComponent } from './features/development-center/pages/finish-strong-details-page/finish-strong-details-page.component';
import { NextLevelPageComponent } from './features/development-center/pages/next-level-page/next-level-page.component';
import { VirtualLearningDetailsPageComponent } from './features/development-center/pages/virtual-learning-details-page/virtual-learning-details-page.component';
import { PlayersPageComponent } from './features/example/pages/players/players-page.component';
import { TeamsPageComponent } from './features/example/pages/teams/teams-page.component';
/*import { FilesMessagesPageComponent } from './features/files-messages/pages/files-messages-page/files-messages-page.component';*/
import { InvestmentsPageComponent } from './features/investments/pages/investments-page.component';
import { RequestTrackerPageComponent } from './features/request-tracker/pages/request-tracker-page.component';
import { ResourceCenterPageComponent } from './features/resource-center/pages/resource-center-page/resource-center-page.component';
import { Lvl2TestPageComponent } from './features/ui-example/pages/lvl2-test/lvl2-test-page.component';
import { UILandingPageComponent } from './features/ui-example/pages/ui-landing/ui-landing-page.component';
import { PortalComponent } from './portal.component';
import { Permission } from 'src/app/core/clients/generated/client';
import { AlphastartPageComponent } from './features/development-center/pages/alphastart-page/alphastart-page.component';
import { CoffeeWithCompliancePageComponent } from './features/development-center/pages/coffee-with-compliance-page/coffee-with-compliance-page.component';
import { SalesSummit2024PageComponent } from './features/development-center/pages/sales-summit-2024-page/sales-summit-2024-page.component';
import { AlphastarContentLibraryPageComponent } from './features/development-center/pages/alphastar-content-library-page/alphastar-content-library-page.component';

const routes: CustomRoutes = [
  {
    path: 'portal',
    component: PortalComponent,
    canActivate: [AuthenticationGuardService],
    canActivateChild: [AuthenticationGuardService],
    data: { title: 'Alphastar Portal' },
    children: [
      // {
      //   path: 'dashboard',
      //   component: DashboardPageComponent,
      //   navigation: {
      //     name: 'Home',
      //     icon: 'home',
      //     order: 0,
      //   }
      // },
      { path: '', redirectTo: 'development-center', pathMatch: 'full' },
      {
        canActivate: [PermissionsGuardService],
        path: 'development-center',
        data: {
          title: 'Development Center'
        },
        permissions: [Permission.ViewDevelopmentCenter],
        navigation: {
          name: 'Development Center',
          icon: 'school',
          order: 1
        },
        children: [
          {
            path: '',
            redirectTo: 'overview',
            pathMatch: 'full',
          },
          {
            path: 'overview',
            component: DevelopmentCenterPageComponent,
            pathMatch: 'full',
          },
          {
            path: 'next-level',
            component: NextLevelPageComponent,
            data: { title: 'Next Level' },
            pathMatch: 'full',
          },
          {
            path: 'alphastart',
            component: AlphastartPageComponent,
            data: { title: 'AlphaStart™' },
            pathMatch: 'full',
          },
          {
            path: 'coffee-with-compliance',
            component: CoffeeWithCompliancePageComponent,
            data: { title: 'Coffee with Compliance' },
            pathMatch: 'full',
          },
          {
            path: 'alphastar-content-library',
            component: AlphastarContentLibraryPageComponent,
            data: { title: 'Alphastar Content Library' },
            pathMatch: 'full',
          },
          {
            path: 'sales-summit-2024',
            component: SalesSummit2024PageComponent,
            data: { title: 'Sales Summit 2024' },
            pathMatch: 'full',
          },
          {
            path: 'virtual-learning-series',
            component: VirtualLearningDetailsPageComponent,
            data: { title: 'Virtual Learning Series' },
            pathMatch: 'full',
          },
          {
            path: 'finish-strong',
            component: FinishStrongDetailsPageComponent,
            data: { title: 'FinishSTRONG™' },
            pathMatch: 'full',
          },
          {
            path: 'best-practice',
            component: UILandingPageComponent,
            data: { title: 'Best Practices and Tutorials' },
            pathMatch: 'full',
          },
        ]
      },
      {
        path: 'investments',
        canActivate: [PermissionsGuardService],
        component: InvestmentsPageComponent,
        data: {
          title: 'Investments'
        },
        permissions: [Permission.ViewBetashieldSso],
        navigation: {
          name: 'Investments',
          icon: 'currency-usd',
          order: 2
        }
      },
      {
        path: 'accounts',
        canActivate: [PermissionsGuardService],
        component: AccountsPageComponent,
        data: {
          title: 'Accounts'
        },
        permissions: [Permission.ViewAccounts],
        navigation: {
          name: 'Accounts',
          icon: 'briefcase',
          order: 3
        }
      },
      {
        path: 'request-tracker',
        canActivate: [PermissionsGuardService],
        component: RequestTrackerPageComponent,
        data: {
          title: 'Service Requests'
        },
        permissions: [Permission.ViewRequestTracker],
        navigation: {
          name: 'Service Requests',
          icon: 'clipboard-clock',
          order: 3
        }
      },
      /*{
        path: 'accounts',
        canActivate: [PermissionsGuardService],
        data: {
          title: 'All Accounts'
        },
        permissions: [Permission.ViewAccounts, Permission.ViewRequestTracker, Permission.ViewFileMessages],
        navigation: {
          name: 'Accounts',
          icon: 'briefcase',
          order: 3
        },
        children: [
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full',
          },
          {
            path: 'list',
            canActivate: [PermissionsGuardService],
            component: AccountsPageComponent,
            data: {
              title: 'All Accounts'
            },
            permissions: [Permission.ViewAccounts],
            navigation: {
              name: 'Accounts',
              icon: 'briefcase-account',
              order: 1,
              absolutePath: 'portal/accounts/list'
            }
          },
          {
            path: 'request-tracker',
            canActivate: [PermissionsGuardService],
            component: RequestTrackerPageComponent,
            data: {
              title: 'Request Tracker'
            },
            permissions: [Permission.ViewRequestTracker],
            navigation: {
              name: 'Request Tracker',
              icon: 'clipboard-clock',
              order: 2,
              absolutePath: 'portal/accounts/request-tracker'
            }
          },
          {
            path: 'upload',
            canActivate: [PermissionsGuardService],
            component: FilesMessagesPageComponent,
            data: {
              title: 'Uploads & Messages'
            },
            permissions: [Permission.ViewFileMessages],
            navigation: {
              name: 'Uploads & Messages',
              icon: 'email',
              order: 3,
              absolutePath: 'portal/accounts/upload'
            }
          },
        ]
      },*/
      {
        path: 'resource-center',
        canActivate: [PermissionsGuardService],
        component: ResourceCenterPageComponent,
        data: {
          title: 'Resource Center'
        },
        permissions: [Permission.ViewResourceCenter],
        navigation: {
          name: 'Resource Center',
          icon: 'lightbulb',
          order: 4
        }
      },
      {
        path: 'ui',
        // navigation: {
        //   name: 'UI Playground',
        //   icon: 'pencil-ruler',
        //   order: 5
        // },
        children: [
          {
            path: '',
            redirectTo: 'playground',
            pathMatch: 'full'
          },
          {
            path: 'playground',
            component: UILandingPageComponent,
            data: { title: 'UI Playground' },
            navigation: {
              name: 'UI Playground',
              icon: 'square',
              absolutePath: 'portal/ui/playground',
              order: 1
            }
          },
          {
            path: 'lvl-2-test',
            component: Lvl2TestPageComponent,
            navigation: {
              name: 'Lvl 2 Test',
              icon: 'circle',
              absolutePath: 'portal/ui/lvl-2-test',
              order: 1
            }
          }
        ]
      },
      {
        path: 'angular-example',
        component: TeamsPageComponent,
        pathMatch: 'full',
        // navigation: {
        //   name: 'Angular Example',
        //   icon: 'test-tube',
        //   order: 6
        // }
      },
      {
        path: 'Team/:teamId/Player/:playerId',
        component: PlayersPageComponent
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: '',
    redirectTo: 'portal',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'portal',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortalRoutingModule { }
