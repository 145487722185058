import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogConfig, MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';//matTooltipPosition: above, below, left, right, before, after
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowIfOneDirective } from './directives/show-if-one.directive';
import { ShowIfAllDirective } from './directives/show-if-all.directive';
import { HideIfOneDirective } from './directives/hide-if-one.directive';
import { OpenSsoAgentSelectModalComponent } from './modals/sso-agent-select-modal/sso-agent-select-modal.component';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { RouterModule } from '@angular/router';
import { FileDropperComponent } from './components/file-dropper/file-dropper.component';
import { NgxCurrencyDirective } from 'ngx-currency';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DebounceModelChangeDirective } from './directives/debounce-model-change.directive';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { CustomMatDialogConfig } from './config/mat-dialog.config';
import { MessageNotificationComponent } from './components/message-notification/message-notification.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SendMessagesAndFilesComponent } from './components/send-messages-and-files/send-messages-and-files.component';

const angularMatModules = [
  MatButtonModule,
  MatIconModule,
  MatCheckboxModule,
  MatDialogModule,
  MatSliderModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatInputModule,
  MatMenuModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatButtonToggleModule,
  ScrollingModule,
];

@NgModule({
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        CustomMatDialogConfig
      }
    }
  ],
  declarations: [
    OpenSsoAgentSelectModalComponent,
    ConfirmationModalComponent,
    HideIfOneDirective,
    ShowIfOneDirective,
    ShowIfAllDirective,
    DebounceModelChangeDirective,
    FileDropperComponent,
    CopyrightComponent,
    MessageNotificationComponent,
    SendMessagesAndFilesComponent,
  ],
  // Any shared module to be used in other features MUST be imported AND exported below
  imports: [
    ...angularMatModules,
    NgxCurrencyDirective,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  // Any shared module to be used in other features MUST be imported AND exported below
  exports: [
    ...angularMatModules,
    NgxCurrencyDirective,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ShowIfOneDirective,
    ShowIfAllDirective,
    HideIfOneDirective,
    DebounceModelChangeDirective,
    RouterModule,
    FileDropperComponent,
    CopyrightComponent,
    MessageNotificationComponent,
    SendMessagesAndFilesComponent,
  ]
})
export class SharedModule {
  // This adds the community material icons to the default package
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }
}
