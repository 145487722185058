<section class="flex w-full h-full p-30">
    <div class="flex flex-col w-full mx-auto max-w-1440 gap-y-30">
      <a routerLink="../overview" class="flex items-center p-5 mr-auto text-sm font-bold uppercase gap-x-5">
        <mat-icon svgIcon="arrow-left" class="mi-18"></mat-icon>
        <span>Back</span>
      </a>
      <section class="flex flex-col gap-y-30">
        <div class="flex gap-x-30">
          <div class="flex flex-col gap-y-10 max-w-640">
            <h2 class="text-xl font-bold">Alphastar Content Library</h2>          
            <p class="text-lg">
              Client-facing materials designed to help advisors break down complex
              concepts and address common investment concerns.
            </p>        
          </div>
        </div>
        <!-- <section class="card">
          <app-development-center-wistia-section class="flex flex-col" [model]="practiceManagementSection"
            [wistiaProject]="wistiaProject"></app-development-center-wistia-section>
        </section>
        <section class="card">
          <app-development-center-wistia-section class="flex flex-col" [model]="clientEngagementSection"
            [wistiaProject]="wistiaProject"></app-development-center-wistia-section>
        </section>
        <section class="card">
          <app-development-center-wistia-section class="flex flex-col" [model]="marketingCollateralSection"
            [wistiaProject]="wistiaProject"></app-development-center-wistia-section>
        </section> -->
        @for (model of wistiaSections; track model; let index = $index) {
          <section class="card">
            <app-development-center-wistia-section class="flex flex-col" [model]="model" [wistiaProject]="wistiaProject"></app-development-center-wistia-section>
          </section>
        }

        @if (!wistiaSections.length) {         
          <div class="mt-25 text-blue">
            Stay tuned! New details coming soon!
          </div>
        }
      </section>
    </div>
  </section>