import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AcmMediaAsset, AcmMediaProject, WistiaClient } from 'src/app/core/clients/generated/client';
import { WistiaProjectsEnum } from 'src/app/shared/enums/wistia.enum';
import { getWistiaSection } from 'src/app/shared/helpers/wistiaSection.helpers';
import { DevelopmentCenterWistiaSection, DevelopmentCenterWistiaSectionEnum } from '../../models/development-center.models';
import { Permission } from 'src/app/core/clients/generated/client';
import { fadeIn } from 'src/app/shared/constants/animations';
import { PermissionsService } from 'src/app/core/auth/permissions.service';

@Component({
  animations: [fadeIn],
  templateUrl: './development-center-page.component.html',
})
export class DevelopmentCenterPageComponent implements OnInit {
  showNextLevelSection = false;
  nextLevelSection?: DevelopmentCenterWistiaSection;
  recentCommunicationSection?: DevelopmentCenterWistiaSection;
  virtualLearningSection?: DevelopmentCenterWistiaSection;
  virtualLearningHighlightVideo?: AcmMediaAsset;
  finishStrongSection?: DevelopmentCenterWistiaSection;
  finishStrongHighlightVideo?: AcmMediaAsset;
  alphastartSection?: DevelopmentCenterWistiaSection;
  alphastartHighlightVideo?: AcmMediaAsset;
  salesSummit2024Section?: DevelopmentCenterWistiaSection;
  salesSummit2024HighlightVideo?: AcmMediaAsset;
  coffeeComplianceSection?: DevelopmentCenterWistiaSection;
  coffeeComplianceHighlightVideo?: AcmMediaAsset;
  alphastarContentLibrarySection?: DevelopmentCenterWistiaSection;
  isLoading = false;
  perm = Permission;
  wistiaProject?: AcmMediaProject;

  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private wistiaApiService: WistiaClient,
    private _permissionsService: PermissionsService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.titleService.setTitle(this.activatedRoute.snapshot.data['title'] + ' | Alphastar Portal');
    this.showNextLevelSection = this._permissionsService.hasOnePermission(Permission.ViewEvolvExperience);
    this.showNextLevelSection = true;

    this.wistiaApiService.getProjectAssets(WistiaProjectsEnum.DevelopmentCenter)
      .subscribe({
        next: result => {
          this.wistiaProject = result;

          // Populate Recent Communication Links
          this.recentCommunicationSection = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.RecentCommunications);

          this.virtualLearningSection = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.VirtualLearningTop);
          this.virtualLearningHighlightVideo = this.virtualLearningSection?.VideoAssets[0].Asset;

          this.finishStrongSection = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.FinishStrongTop);
          this.finishStrongHighlightVideo = this.finishStrongSection?.VideoAssets[0].Asset;

          this.alphastartSection = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.AlphaStartTop);
          this.alphastartHighlightVideo = this.alphastartSection?.VideoAssets[0].Asset;

          this.salesSummit2024Section = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.SalesSummit2024Top);
          this.salesSummit2024HighlightVideo = this.salesSummit2024Section?.VideoAssets[0].Asset;

          //this.coffeeComplianceSection = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.CoffeeWithComplianceTop);
          //this.coffeeComplianceHighlightVideo = this.coffeeComplianceSection?.VideoAssets[0].Asset;

          this.alphastarContentLibrarySection = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.AlphastarContentLibrary);

          // Populate Next Level Videos
          if (this.showNextLevelSection){
            this.nextLevelSection = getWistiaSection(this.wistiaProject, DevelopmentCenterWistiaSectionEnum.NextLevelTop);
            if (this.nextLevelSection) {
              this.nextLevelSection.VideoAssets = this.nextLevelSection?.VideoAssets.slice(0, 1);
            }
          }
        }
      })
      .add(() => this.isLoading = false);
  }

}
