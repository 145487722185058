<div id="requestTrackerDetailModal" class="flex flex-col w-full h-full">
  <header>
    <h2 class="m-10 mr-20 text-lg font-bold">{{data.category}} {{data.subCategory !== null ? ' - ' + data.subCategory :
      ''}} Request Details</h2>
    <button mat-button class="m-10 ml-auto icon-button is-primary is-inverted" tabindex="-1" type="button"
      mat-dialog-close>
      <i class="material-icons">close</i>
    </button>
  </header>
  <section class="flex flex-row max-h-720">
    <section class="flex flex-col w-2/5 overflow-auto p-30 gap-y-20">

      <div class="flex flex-col gap-y-5">
        <label class="mb-5 text-md font-bold text-gray-dark">Account Details</label>

        @for (detail of details | keyvalue: sortNull; track detail) {
          @if (detail.key === clientTitle) {
          <div class="flex flex-col gap-y-5">
            <div class="flex justify-between border-dotted border-gray-light">
              <div class="text-gray-dark">
                {{detail.key}} </div>
              <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
              @for (client of clients; track client; let lastClient = $last) {
              <div class="font-bold text-gray-dark">
                @if (detail.key === clientTitle) {
                <span>{{client.firstName}} {{client.lastName}}@if (!lastClient) {
                  <span>,&nbsp;</span>
                  }</span>
                }
              </div>
              }
            </div>
          </div>
          }
          @if (detail.key === "Account #") {
          <div class="flex flex-col gap-y-5">
            <div class="flex justify-between border-dotted border-gray-light">
              <div class="text-gray-dark">
                {{detail.key}} </div>
              <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
              <div class="font-bold text-gray-dark">
                <span>{{detail.value}}</span>
              </div>
            </div>
          </div>
          }

          @if (detail.key === "Account Type") {
          <div class="flex flex-col gap-y-5">
            <div class="flex justify-between border-dotted border-gray-light">
              <div class="text-gray-dark">
                {{detail.key}} </div>
              <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
              <div class="font-bold text-gray-dark">
                <span>{{detail.value}}</span>
              </div>
            </div>
          </div>
          }

      
          @if (detail.key === "Custodian") {
            <div class="flex flex-col gap-y-5">
              <div class="flex justify-between border-dotted border-gray-light">
                <div class="text-gray-dark">
                  {{detail.key}} </div>
                <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                <div class="font-bold text-gray-dark">
                  <span>{{detail.value}}</span>
                </div>
              </div>
            </div>
          }
          @if (detail.key === "Account Model") {
            <div class="flex flex-col gap-y-5">
              <div class="flex justify-between border-dotted border-gray-light">
                <div class="text-gray-dark">
                  {{detail.key}} </div>
                <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                <div class="font-bold text-gray-dark">
                  <span>{{detail.value}}</span>
                </div>
              </div>
            </div>
          }
        }

        @if (statusObj.Status) {
        <div class="flex flex-col gap-y-5">
          <div class="flex justify-between border-dotted border-gray-light">
            <span class="text-gray-dark">Current Status:</span>
            <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
            <div class="font-bold text-gray-dark flex-row">
              <span [ngStyle]="{'color': 
                statusObj.StatusId === 4 ? '#D71D00' : 
                statusObj.StatusId === 5 ? '#127161' : 
                statusObj.StatusId === 6 ? '#FFAF3D' : 
                statusObj.StatusId === 7 ? '#D71D00' : 
                statusObj.StatusId === 8 ? '#D71D00' : 
                statusObj.StatusId === 9 ? '#D71D00' : 
                ''}"> {{ statusObj.Status }}</span>
            </div>
          </div>
        </div>
        }

        <div class="mb-5 mt-10 flex flex-col gap-y-10">
          <span class="text-base font-bold">Request Notes:</span>
          @if (note && note.length) {
          <div class="flex flex-col p-5 rounded bg-gray-lightest">
            <span class="whitespace-pre-wrap overflow-wrap-text text-gray-dark text-sm">{{note}}</span>
          </div>
          } @else {
          <em class="text-sm text-gray-light">No notes</em>
          }
        </div>
        @if (data.category !== requestTrackerTypeEnum.PlanningDesk && data.category !== requestTrackerTypeEnum.GeneralAccount) {
        <label class="mb-5 mt-10 text-md font-bold text-gray-dark">Original Account Setup</label>
        <div class="flex flex-col gap-y-5">
          <div class="flex justify-between border-dotted border-gray-light">
            <div class="text-gray-dark">
              Custodian</div>
            <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
            <div class="font-bold text-gray-dark">
              <span>{{data.custodian}}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-y-5">
          <div class="flex justify-between border-dotted border-gray-light">
            <div class="text-gray-dark">
              Account Model</div>
            <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
            <div class="font-bold text-gray-dark">
              <span>{{data.investmentAccountModel}}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-y-5">
          <div class="flex justify-between border-dotted border-gray-light">
            <div class="text-gray-dark">
              Total Account Fee</div>
            <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
            <div class="font-bold text-gray-dark">
              <span>{{ message?.account?.Fee }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="flex flex-col gap-y-5">
        <div class="flex justify-between border-dotted border-gray-light">
          <div class="text-gray-dark">
           Percentage</div>
          <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>             
          <div class="font-bold text-gray-dark">              
            <span>TODO</span>                
          </div>              
        </div>
      </div> -->

        <label class="mb-5 mt-10 text-md font-bold text-gray-dark">Requested Account Setup</label>
        }


        @if (data.category === requestTrackerTypeEnum.PlanningDesk || data.category === requestTrackerTypeEnum.GeneralAccount) {
        <label class="mb-5 mt-10 text-md font-bold text-gray-dark">Request Details</label>
        }

      </div>
      <!-- <span class="flex-none divider-horizontal"></span> -->

      <div class="flex flex-col">
        @for (detail of details | keyvalue: sortNull; track detail) {
        @if (detail.key !== clientTitle && detail.key !== "Account #" && detail.key !== "Account Type" && detail.key !== "Custodian" && detail.key !== "Account Model" )
        {
        <div class="flex-col py-2">
          <div class="flex justify-between border-dotted border-gray-light">
            <div class=" text-gray-dark"
              [ngClass]="{'font-bold mt-10': detail.key === sleevesTitle || detail.key === accountsTitle  || detail.key === tradesTitle}">
              {{detail.key}}</div>
            @if (detail.key !== sleevesTitle && detail.key !== accountsTitle && detail.key !== tradesTitle) {
            <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
            }
            @if (detail.key !== sleevesTitle && detail.key !== accountsTitle && detail.key
            !== tradesTitle) {
            <div class="font-bold text-gray-dark">
              {{detail.value}} </div>
            }
            @if (detail.key === grossContributionAmountTitle) {
            <div class="font-bold text-gray-dark">{{grossContributionAmount |
              currency}}</div>
            }
            @if (detail.key === grossDistributionAmountTitle) {
            <div class="font-bold text-gray-dark">{{grossDistributionAmount |
              currency}}</div>
            }
          </div>
          @if (detail.key === accountsTitle) {
          <div class="flex flex-col w-full">
            @for (account of planningDeskAccounts; track account) {
            <div class="flex-col py-2 ml-20">
              <div class="flex justify-between border-dotted border-gray-light">
                <span class="text-gray-dark">Account Type</span>
                <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                <span class="font-bold text-gray-dark">{{account.investmentAccountType}} </span>
              </div>
              <div class="flex justify-between border-dotted ml-15 border-gray-light">
                <span class="text-gray-dark">Current Account Value</span>
                <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                <span class="font-bold text-gray-dark">{{account.currentAccountValue | currency}} </span>
              </div>
              <div class="flex justify-between border-dotted ml-15 border-gray-light">
                <span class="text-gray-dark">Client Fee</span>
                <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                <span class="font-bold text-gray-dark">{{account.clientFee | percent: '1.0-3'}} </span>
              </div>
            </div>
            }
          </div>
          }
          @if (detail.key === sleevesTitle) {
          <div class="flex flex-col w-full">
            @for (sleeve of sleeves; track sleeve) {
            <div class="flex-col py-2 ml-20 pt-5">
              <div class="flex justify-between border-dotted border-gray-light">
                <span class=" text-gray-dark">Account Model</span>
                <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                <span class="font-bold text-gray-dark">{{sleeve.investmentAccountModel || 'No Model'}} </span>
              </div>
              <div class="flex justify-between border-dotted ml-15 border-gray-light">
                <span class=" text-gray-dark">Amount</span>
                <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                <span class="font-bold text-gray-dark">{{sleeve.amount | currency}}</span>
              </div>
              @if (data.category === requestTrackerTypeEnum.Reallocation) {
              <div class="flex justify-between border-dotted ml-15 border-gray-light">
                <span class=" text-gray-dark">Total Account Fee</span>
                <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                <span class="font-bold text-gray-dark">{{sleeve.accountTotalFee | percent: '1.0-3'}} </span>
              </div>
              }
              <div class="flex justify-between border-dotted ml-15 border-gray-light">
                <span class=" text-gray-dark">Percentage</span>
                <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                <span class="font-bold text-gray-dark">{{(sleeve.amount / grossSleeveAmount) | percent: '1.2'}}</span>
              </div>
            </div>
            }
          </div>
          }
          @if (detail.key === tradesTitle) {
          <div class="flex flex-col w-full">
            @if (tradeInstructions !== null && tradeInstructions.length) {
            <div class="flex flex-col py-5 gap-y-10">
              <div class="flex flex-col p-5 rounded bg-gray-lightest">
                <span class="whitespace-pre-wrap overflow-wrap-text text-gray-dark text-sm">{{tradeInstructions}}</span>
              </div>
            </div>
            }
            @for (trade of trades; track trade) {
            <div class="flex-col py-2 ml-20 pt-5">
              <div class="flex justify-between border-dotted border-gray-light">
                <span class=" text-gray-dark">Instruction</span>
                <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                <span class="font-bold text-gray-dark">{{trade.instruction}}</span>
              </div>
              @if (trade.instruction.toLowerCase().indexOf('all') === -1) {
              <div class="flex justify-between border-dotted ml-15 border-gray-light">
                <span class=" text-gray-dark">Amount</span>
                <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                <span class="font-bold text-gray-dark">{{ trade.instruction.toLowerCase().indexOf('dollars') !== -1 ?
                  (trade.amount | currency) : trade.amount
                  }} </span>
              </div>
              }
              <div class="flex justify-between border-dotted ml-15 border-gray-light">
                <span class=" text-gray-dark">Symbol</span>
                <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                <span class="font-bold text-gray-dark">{{trade.symbol}}</span>
              </div>
            </div>
            }
          </div>
          }
        </div>
        }
        }
      </div>
    </section>
    <section class="flex flex-col w-3/5 overflow-auto pl-15 pr-15 gap-y-10">           
        <app-send-messages-and-files [fileMessageData]="message" (refreshParentData)="getData()"></app-send-messages-and-files>
        <app-message-and-file-timeline [notesAndFiles]="notesAndFiles" [filePrefix]="filePrefix"></app-message-and-file-timeline>      
    </section>
  </section>

</div>