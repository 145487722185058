import { Component, Input } from '@angular/core';
import { fadeIn } from 'src/app/shared/constants/animations';
import { MessageAndFileModel } from '../../models/request-tracker-models';

@Component({
  selector: 'app-message-and-file-timeline',
  templateUrl: './message-and-file-timeline.component.html',
  animations: [fadeIn],
})
export class MessagesAndFileTimelineComponent {
  constructor(
  ) { }

  @Input() notesAndFiles?: MessageAndFileModel[];
  @Input() filePrefix?: string;
}
