import { Recipient } from "../models/files-messages.model";


export const recipientsListData: Recipient[] = [
  {
    recipientId: 1,
    recipientName: 'Operations'
  },
  {
    recipientId: 2,
    recipientName: 'Trading'
  }
];